import { EARLIEST_DELIVERY_START } from '../../../constants';
import { ProviderChangeReason } from '../../../graphql/generated';
import { in20Days, inDays } from '../../../utils/utils';

const { FirstTimeOccupancy, Relocation, SupplierChange } = ProviderChangeReason;

export const getMinimumDeliveryStartDate = (
  providerChangeReason: ProviderChangeReason = SupplierChange,
): Date => {
  const thirtyDaysBefore = inDays(0);
  thirtyDaysBefore.setDate(thirtyDaysBefore.getDate() - 30);
  return providerChangeReason === SupplierChange ? in20Days() : thirtyDaysBefore;
};

export const getMaximumDeliveryStartDate = (
  providerChangeReason: ProviderChangeReason = SupplierChange,
): Date => {
  const daysLater = providerChangeReason === SupplierChange ? 365 : 90;
  return new Date(inDays(0).setDate(inDays(0).getDate() + daysLater));
};

export const getNotTerminatedContractLocaleKey = (
  providerChangeReason: ProviderChangeReason = SupplierChange,
) => {
  // TODO: remove after Jan 1st 2025
  const notTerminatedLocaleKey =
    Date.now() <= EARLIEST_DELIVERY_START.getTime() - 20 * 24 * 60 * 60 * 1000
      ? 'data.deliveryDetails.earliestPossibleDeliveryStartDateBySonnen'
      : 'data.deliveryDetails.earliestDeliveryDateInfo';

  switch (providerChangeReason) {
    case FirstTimeOccupancy:
    case Relocation:
      // TODO: update the deliveryStartDateInfo translation after Jan 1st 2025
      return `data.providerChangeReason.${providerChangeReason}.deliveryStartDateInfo`;
    default:
      return notTerminatedLocaleKey;
  }
};
