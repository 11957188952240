import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { AuthenticationProvider } from '@sonnen/web-utils/components';
import { Sentry } from '@sonnen/web-utils';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppContextProvider } from './AppContext/AppContext';
import { addTranslations, useTranslation } from './i18n/i18n';
import { getRedirectUri } from './utils/utils';

import * as de from './locales/de.json';
import * as enGb from './locales/en-GB.json';
import { COOKIES_CONSENT_EVENT, COOKIES_CONSENT_KEY } from './constants';

addTranslations({ de, 'en-GB': enGb });

const element = document.getElementById('root');
const root = ReactDOM.createRoot(element!);

const Main = () => {
  const apiUrl = import.meta.env.VITE_AUTH_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH_CLIENT_ID;

  const { changeLanguage } = useTranslation();

  if (!apiUrl || !clientId) {
    throw new Error(
      `Missing required environment variables VITE_AUTH_DOMAIN and VITE_AUTH_CLIENT_ID: ${apiUrl}, ${clientId}`,
    );
  }

  const redirectUri = useMemo(() => getRedirectUri(), []);

  const authConfig = {
    autoLogin: false,
    apiUrl,
    clientId,
    redirectUri,
    scope: 'openid',
  };

  const instantiateSentry = () => {
    const appEnv = process.env.VITE_ENV;
    const sentryDns = process.env.VITE_SENTRY_DSN;
    const cookiesConsent = localStorage.getItem(COOKIES_CONSENT_KEY);

    if (appEnv && sentryDns) {
      if (cookiesConsent === 'true') new Sentry(appEnv, sentryDns);
      if (cookiesConsent === 'false') new Sentry(appEnv, sentryDns, false);
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const lang = url.searchParams.get('lang');
    changeLanguage(lang?.startsWith('en') ? 'en-GB' : 'de');

    instantiateSentry();

    window.addEventListener(COOKIES_CONSENT_EVENT, instantiateSentry);
    return () => {
      window.removeEventListener(COOKIES_CONSENT_EVENT, instantiateSentry);
    };
  }, []);

  return (
    <React.StrictMode>
      <AuthenticationProvider config={authConfig}>
        <AppContextProvider>
          <BrowserRouter
            basename={process.env.VITE_URL_BASE_PATH || '/'}
            future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
          >
            <App />
          </BrowserRouter>
        </AppContextProvider>
      </AuthenticationProvider>
    </React.StrictMode>
  );
};

root.render(<Main />);
