import { Typography } from '@sonnen/web-ui';
import styles from './SummaryCard.module.css';

type SummaryCardProps = {
  title: string;
  subtitle?: string;
  value: string;
  currency: string;
  variant?: 'small' | 'medium' | 'large';
};

/**
 * SummaryCard component.
 *
 * @param title {String} - Mandatory card title.
 * @param subtitle {String} - Optional card subtitle.
 * @param value {String} - Mandatory value displayed with large text.
 * @param currency {String} - Mandatory text displayed after value.
 * @param variant {String} - Optional variant that determines the SummaryCard size.
 * @param background {String} - Optional variant that determines the SummaryCard size.
 *
 */

const SummaryCard = ({
  title,
  subtitle = '',
  value,
  currency,
  variant = 'large',
}: SummaryCardProps) => {
  let cardTitle = <Typography.Label2 variant="secondary">{title}</Typography.Label2>;
  let cardSubtitle = <Typography.Label2 variant="secondary">{subtitle}</Typography.Label2>;
  let valueAndCurrency = (
    <>
      <Typography.Number2>{value}</Typography.Number2>
      <Typography.H4>{currency}</Typography.H4>
    </>
  );

  if (variant === 'medium') {
    cardTitle = <Typography.Label2 variant="secondary">{title}</Typography.Label2>;
    cardSubtitle = <Typography.Label2 variant="secondary">{subtitle}</Typography.Label2>;
    valueAndCurrency = (
      <>
        <Typography.Number3>{value}</Typography.Number3>
        <Typography.Label1>{currency}</Typography.Label1>
      </>
    );
  }
  if (variant === 'small') {
    cardTitle = <Typography.Caption1>{title}</Typography.Caption1>;
    cardSubtitle = <Typography.Caption2>{title}</Typography.Caption2>;
    valueAndCurrency = (
      <>
        <Typography.Number3>{value}</Typography.Number3>
        <Typography.Label2>{currency}</Typography.Label2>
      </>
    );
  }

  return (
    <div className={styles.summaryCard} data-testid="summary-card">
      {cardTitle}
      <div className={styles.valueCurrencyWrapper}>{valueAndCurrency}</div>
      {cardSubtitle}
    </div>
  );
};

export default SummaryCard;
