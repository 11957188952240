import { useContext } from 'react';
import { OfferContext } from '../OfferContext/OfferContext';
import { AppRoute, ROUTE_FLOW, skippableRoutes } from '../constants';
import { usePathname } from './usePathname';
import { useTopScrollNavigate } from './useTopScrollNavigate';

const getSurroundingRoutes = (
  currentRoute: AppRoute,
): {
  next?: string;
  previous?: string;
} => {
  return {
    next: ROUTE_FLOW[ROUTE_FLOW.indexOf(currentRoute) + 1],
    previous: ROUTE_FLOW[ROUTE_FLOW.indexOf(currentRoute) - 1],
  };
};

type UseOrderFlowNavigationProps = {
  navigate: (path: string) => void;
  navigateNext?: () => void;
  navigatePrevious?: () => void;
  next?: string;
  previous?: string;
  skippableRoute: boolean;
};

export const useOrderFlowNavigation = (): UseOrderFlowNavigationProps => {
  const pathname = usePathname();
  const navigate = useTopScrollNavigate();
  const { offer } = useContext(OfferContext);
  const skippableRoute = skippableRoutes.has(pathname);

  if (!offer) return { navigate, skippableRoute };

  const { next, previous } = getSurroundingRoutes(pathname);

  const navigateNext = () => {
    if (next) navigate(next);
  };

  const returns: UseOrderFlowNavigationProps = {
    next,
    previous,
    navigate,
    skippableRoute,
  };

  if (previous) returns.navigatePrevious = () => navigate(previous);
  if (next) returns.navigateNext = navigateNext;

  return returns;
};
