import styles from './CheckAnimation.module.css';

export const CheckAnimation = () => (
  <div className={styles.checkAnimation} data-testid="check-animation">
    <svg viewBox="0 0 130 130">
      <circle
        className={`${styles.path} ${styles.circle}`}
        fill="none"
        strokeWidth="9"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="60"
      />
      <polyline
        className={`${styles.path} ${styles.check}`}
        fill="none"
        strokeWidth="9"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  </div>
);
