import { ApolloError } from '@apollo/client';
import { useAuth } from '@sonnen/web-utils/hooks';
import { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext/AppContext';
import { CurrentUser, useGetCurrentUserQuery } from '../graphql/generated';
import { AppContextType } from '../types';

/**
 * Hook to get current user data from backend and automatically update
 * the user in the AppContext.
 * @returns Current user from API, loading state and error
 */
export const useCurrentUserData = (): [
  CurrentUser | null | undefined,
  boolean,
  ApolloError | undefined,
] => {
  const { user: authUser, loading: authLoading } = useAuth<CurrentUser>();
  const { setUser, setUserProcessing } = useContext<AppContextType>(AppContext);

  const {
    data: userData,
    loading: userDataLoading,
    error,
  } = useGetCurrentUserQuery({
    skip: !authUser?.access_token,
  });

  useEffect(() => {
    const userProcessed = (!authLoading && !authUser?.access_token) || userData || error;
    if (userProcessed) setUserProcessing(false);
  }, [authLoading, authUser, userData, error]);

  useEffect(() => {
    if (userData?.currentUser) setUser(userData.currentUser);
  }, [userData]);

  return [userData?.currentUser, userDataLoading, error];
};
