import {
  CreateProductConfigurationsInput,
  CustomerUserAccountType,
  ProductConfigurations,
} from './graphql/generated';
import {
  AppContextType,
  ModuleName,
  OfferContextType,
  TAddress,
  TFormValues,
  TPersonalData,
} from './types';

const getTodayZeroHours = (): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

export const EMPTY_PERSONAL_DATA: TPersonalData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

export const EMPTY_DELIVERY_ADDRESS: TAddress = {
  city: '',
  postalCode: '',
  streetName: '',
  country: 'DE',
  streetNumber: '',
};

export const EARLIEST_DELIVERY_START = new Date('2025-01-01T00:00:00.000Z');
export const twentyDays = 20 * 24 * 60 * 60 * 1000;
const defaultDeliveryStartDate = new Date(getTodayZeroHours().getTime() + twentyDays);
export const EMPTY_BILLING_ADDRESS: TAddress = {
  city: '',
  postalCode: '',
  streetName: '',
  country: 'DE',
  streetNumber: '',
};

export const defaultFormValues: TFormValues = {
  accountOwner: '',
  confirmedCancellationDate: new Date(),
  contractTerminated: false,
  terminatedEnergyProviderName: undefined,
  personalData: EMPTY_PERSONAL_DATA,
  deliveryAddress: EMPTY_DELIVERY_ADDRESS,
  deliveryStartDate:
    defaultDeliveryStartDate > EARLIEST_DELIVERY_START
      ? defaultDeliveryStartDate
      : EARLIEST_DELIVERY_START,
  billingAddress: EMPTY_BILLING_ADDRESS,
  iban: '',
  meterId: '',
  paymentAuthorization: false,
  termsAndConditions: false,
  marketing: false,
  interestedInHardwareOffer: false,
};

export const DEFAULT_APP_CONTEXT: AppContextType = {
  accountType: CustomerUserAccountType.PrivateCustomer,
  formValues: defaultFormValues,
  offerRequiresAddress: false,
  orderComplete: false,
  selectedSite: null,
  setAccountType: () => {},
  setFormValues: () => {},
  setOfferRequiresAddress: () => {},
  setOrderComplete: () => {},
  setSelectedSite: () => {},
  setUser: () => {},
  setUserProcessing: () => {},
  user: null,
  userProcessing: true,
  modules: new Set([ModuleName.PRICE_OPTIMIZATION]),
  addModule: () => {},
  removeModule: () => {},
  toggleModule: () => {},
  customBillingAddress: false,
  setCustomBillingAddress: () => {},
};
export const DEFAULT_PERSONS = 2;

export const PREDEFINED_CONSUMPTION: { [key: string]: number } = {
  1: 1400,
  2: 2300,
  3: 3000,
  4: 3700,
  5: 4400,
  6: 5100,
  7: 5800,
  8: 6500,
};

export const initialOfferInput: CreateProductConfigurationsInput = {
  deliveryAddress: EMPTY_DELIVERY_ADDRESS,
  expectedElectricityConsumptionInKwh: PREDEFINED_CONSUMPTION[2],
  workEmail: '',
};

export const DEFAULT_OFFER_CONTEXT: OfferContextType = {
  deferredOfferLoading: false,
  offer: null,
  offerLoading: false,
  offerError: null,
  createProductConfigurations: () => {},
  productConfigurationsInput: initialOfferInput,
  setProductConfigurationsInput: () => {},
  personsInHousehold: DEFAULT_PERSONS,
  setPersonsInHousehold: () => {},
  workEmail: '',
  setWorkEmail: () => {},
};

export enum AppRoute {
  ROOT = '/',
  OFFER_CALCULATION = '/offer-calculation',
  PRICE_OPTIMIZATION = '/price-optimization',
  DATA_ENTRY = '/data-entry',
  DATA_REVIEW = '/data-review',
  ORDER_COMPLETE = '/order-complete',
  LOGIN = '/login',
  LOGOUT = '/logout',
}

export const ROUTE_FLOW = [
  AppRoute.ROOT,
  AppRoute.OFFER_CALCULATION,
  AppRoute.PRICE_OPTIMIZATION,
  AppRoute.DATA_ENTRY,
  AppRoute.DATA_REVIEW,
  AppRoute.ORDER_COMPLETE,
];

export const skippableRoutes: Set<AppRoute> = new Set([
  AppRoute.OFFER_CALCULATION,
  AppRoute.PRICE_OPTIMIZATION,
]);

export const nextLabelByPath: Record<AppRoute, string> = {
  [AppRoute.OFFER_CALCULATION]: 'offer.personalizeTariff',
  [AppRoute.PRICE_OPTIMIZATION]: 'offer.offerBtn',
  [AppRoute.DATA_ENTRY]: 'data.continueAndReview',
  [AppRoute.DATA_REVIEW]: 'review.orderNow',
  [AppRoute.ORDER_COMPLETE]: '',
  [AppRoute.ROOT]: '',
  [AppRoute.LOGIN]: '',
  [AppRoute.LOGOUT]: '',
};

export const noBottomCtaRoutes: AppRoute[] = [
  AppRoute.ROOT,
  AppRoute.DATA_ENTRY,
  AppRoute.DATA_REVIEW,
];

export const modulePropertyByName: Record<
  ModuleName,
  keyof Pick<ProductConfigurations, 'configurationWithBatteryOptimizationModule'>
> = {
  [ModuleName.PRICE_OPTIMIZATION]: 'configurationWithBatteryOptimizationModule',
};

export const COOKIES_CONSENT_KEY = 'tariff:cookieConsent';
export const COOKIES_CONSENT_EVENT = 'cookies-consent-update';
