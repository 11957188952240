import { useMobile } from '@sonnen/web-ui';

import Optimization_mobile from '../../assets/images/Optimization_mobile.webp';
import Optimization_wide from '../../assets/images/Optimization_wide.webp';
import SmartMeter from '../../assets/images/SmartMeter.webp';
import CardImage from '../../components/CardImage/CardImage';
import { CustomUSPCard } from '../../components/CustomUSPCard/CustomUSPCard';
import { OrderFlowPage } from '../../components/OrderFlowPage/OrderFlowPage';
import USPCard from '../../components/USPCard/USPCard';
import { useTranslation } from '../../i18n/i18n';
import { EnergyDynamicUSP } from './EnergyDynamicUSP/EnergyDynamicUSP';
import styles from './OfferCalculation.module.css';
import OfferCalculationForm from './OfferCalculationForm/OfferCalculationForm';
import { memoize } from '../../utils/utils';

const OfferCalculation = () => {
  const { t } = useTranslation();
  const mobile = useMobile();

  return (
    <div className={styles.offerCalculation} data-testid="offer-calculation">
      <OfferCalculationForm />

      {memoize(
        <OrderFlowPage title={t('home.title')} className={styles.layoutContainer}>
          <CustomUSPCard i18nScope="home.cardChart">
            <EnergyDynamicUSP />
          </CustomUSPCard>

          <USPCard>
            <CardImage
              src={mobile ? Optimization_mobile : Optimization_wide}
              dataTestId="optimization"
            />
          </USPCard>

          <CustomUSPCard i18nScope="home.cardSmartMeter">
            <CardImage src={SmartMeter} dataTestId="smart-meter" className={styles.smartMeterImg} />
          </CustomUSPCard>
        </OrderFlowPage>,
      )}
    </div>
  );
};

export default OfferCalculation;
