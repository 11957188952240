import { Typography } from '@sonnen/web-ui';
import styles from './ModuleRow.module.css';

type ModuleRowProps = {
  name: string;
  value: string;
  currency: string;
  description: string;
  dataTestId: string;
};

const ModuleRow = ({ name, value, currency, description, dataTestId }: ModuleRowProps) => (
  <div className={styles.moduleRow} data-testid={dataTestId}>
    <div className={styles.nameAndValue}>
      <Typography.Label1>{name}</Typography.Label1>

      <span className={styles.valueWithCurrency}>
        <Typography.Number4>{value}</Typography.Number4>{' '}
        <Typography.Label2>{currency}</Typography.Label2>
      </span>
    </div>
    <Typography.Body2>{description}</Typography.Body2>
  </div>
);

export default ModuleRow;
