import { Button, Typography, useMobile } from '@sonnen/web-ui';
import classNames from 'classnames';
import { useContext } from 'react';
import { OfferContext } from '../../OfferContext/OfferContext';
import { AppRoute, nextLabelByPath, skippableRoutes } from '../../constants';
import { useOrderFlowNavigation } from '../../hooks/useOrderFlowNavigation';
import { usePathname } from '../../hooks/usePathname';
import { useTopScrollNavigate } from '../../hooks/useTopScrollNavigate';
import { useTranslation } from '../../i18n/i18n';
import { ModuleName } from '../../types';
import { formatCurrency } from '../../utils/formatting';
import { parseHtmlTags } from '@sonnen/web-utils';
import { ModuleToggle } from '../ModuleToggle/ModuleToggle';
import OfferOverlay from '../OfferOverlay/OfferOverlay';
import styles from './MobileCta.module.css';

const DefaultCTA = ({ value }: { value: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.text} data-testid="default-cta">
      <Typography.Body1>{t('offer.summaryCardTitle')}</Typography.Body1>
      <span>
        <Typography.Number2>{value}</Typography.Number2>
        <Typography.Label1>{`€/${t('offer.month')}`}</Typography.Label1>
      </span>
    </div>
  );
};

const PriceOptimizationToggle = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.text} data-testid="price-optimization-toggle">
      <Typography.Body1>
        <div>{parseHtmlTags(t('modules.priceOptimization.toggleCta'))}</div>
      </Typography.Body1>
      <ModuleToggle name={ModuleName.PRICE_OPTIMIZATION} />
    </div>
  );
};

const MobileCta = () => {
  const { offer, offerLoading, deferredOfferLoading, offerError } = useContext(OfferContext);
  const { t } = useTranslation();
  const navigate = useTopScrollNavigate();
  const { next } = useOrderFlowNavigation();
  const pathname = usePathname();
  const nextLabel = t(nextLabelByPath[pathname] || 'offer.offerBtn');
  const mobile = useMobile();
  const routeWithoutValidation = skippableRoutes.has(pathname);

  const visible = mobile && Boolean(offer || offerLoading || offerError) && routeWithoutValidation;

  if (!mobile) return false;

  const benefit = offer?.configurationWithBatteryOptimizationModule
    ? offer.configurationWithBatteryOptimizationModule.expectedPaymentGrossPerMonth
    : offer?.configurationWithoutBatteryOptimizationModule.expectedPaymentGrossPerMonth;

  const onOfferBtnClick = () => {
    if (deferredOfferLoading || !next) return;
    navigate(next);
  };

  return (
    <div
      className={classNames(styles.mobileCta, { [styles.visible]: visible })}
      data-testid="mobile-cta"
    >
      <OfferOverlay />

      {pathname.startsWith(AppRoute.PRICE_OPTIMIZATION) ? (
        <PriceOptimizationToggle />
      ) : (
        <DefaultCTA value={formatCurrency(benefit, 'euro', false)} />
      )}

      <Button
        label={nextLabel}
        onClick={onOfferBtnClick}
        dataTestId="mobile-cta-btn"
        loading={deferredOfferLoading}
        expanded
      />
    </div>
  );
};

export default MobileCta;
