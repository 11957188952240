import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
};

export type CreateCustomerOrderBillingAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  streetName: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
};

export type CreateCustomerOrderDeliveryAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  streetName: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
};

export type CreateCustomerOrderPaymentDetailsInput = {
  accountOwner: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  workEmail: Scalars['String']['input'];
};

export type CreateExistingCustomerOrderAcceptedInput = {
  paymentAuthorization: Scalars['Boolean']['input'];
  termsAndConditions: Scalars['Boolean']['input'];
};

export type CreateExistingCustomerOrderDeliveryDetailsInput = {
  batteryId?: InputMaybe<Scalars['String']['input']>;
  confirmedCancellationDate?: InputMaybe<Scalars['Date']['input']>;
  consumptionEstimated: Scalars['Int']['input'];
  contractTerminated: Scalars['Boolean']['input'];
  deliveryStartDate: Scalars['Date']['input'];
  meterId: Scalars['String']['input'];
  productEngineConfigUrl: Scalars['String']['input'];
  terminatedEnergyProviderId?: InputMaybe<Scalars['String']['input']>;
  terminatedEnergyProviderName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateExistingCustomerOrderInput = {
  accepted: CreateExistingCustomerOrderAcceptedInput;
  billingAddress?: InputMaybe<CreateCustomerOrderBillingAddressInput>;
  deliveryAddress: CreateCustomerOrderDeliveryAddressInput;
  deliveryDetails: CreateExistingCustomerOrderDeliveryDetailsInput;
  paymentDetails: CreateCustomerOrderPaymentDetailsInput;
  personalData: CreateExistingCustomerOrderPersonalDataInput;
};

export type CreateExistingCustomerOrderPersonalDataInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateNewCustomerOrderAcceptedInput = {
  marketing: Scalars['Boolean']['input'];
  paymentAuthorization: Scalars['Boolean']['input'];
  termsAndConditions: Scalars['Boolean']['input'];
};

export type CreateNewCustomerOrderDeliveryDetailsInput = {
  confirmedCancellationDate?: InputMaybe<Scalars['Date']['input']>;
  consumptionEstimated: Scalars['Int']['input'];
  contractTerminated: Scalars['Boolean']['input'];
  deliveryStartDate: Scalars['Date']['input'];
  meterId: Scalars['String']['input'];
  productEngineConfigUrl: Scalars['String']['input'];
  providerChangeReason: ProviderChangeReason;
  terminatedEnergyProviderId?: InputMaybe<Scalars['String']['input']>;
  terminatedEnergyProviderName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNewCustomerOrderInput = {
  accepted: CreateNewCustomerOrderAcceptedInput;
  billingAddress?: InputMaybe<CreateCustomerOrderBillingAddressInput>;
  deliveryAddress: CreateCustomerOrderDeliveryAddressInput;
  deliveryDetails: CreateNewCustomerOrderDeliveryDetailsInput;
  interestedInHardwareOffer?: InputMaybe<Scalars['Boolean']['input']>;
  paymentDetails: CreateCustomerOrderPaymentDetailsInput;
  personalData: CreateNewCustomerOrderPersonalDataInput;
};

export type CreateNewCustomerOrderPersonalDataInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  salutation: CustomerSalutation;
};

export type CreateProductConfigurationDeliveryAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductConfigurationsInput = {
  batteryId?: InputMaybe<Scalars['String']['input']>;
  deliveryAddress: CreateProductConfigurationDeliveryAddressInput;
  expectedElectricityConsumptionInKwh: Scalars['Int']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
  workEmail: Scalars['String']['input'];
};

export enum Currency {
  Eur = 'EUR'
}

export type CurrencyProperty = {
  __typename?: 'CurrencyProperty';
  currency: Currency;
  value: Scalars['String']['output'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  account: CurrentUserAccount;
  contact: CurrentUserContact;
  id: Scalars['String']['output'];
  sites?: Maybe<Array<CurrentUserSite>>;
};

export type CurrentUserAccount = {
  __typename?: 'CurrentUserAccount';
  billingAddress: CurrentUserAccountBillingAddress;
  companyName?: Maybe<Scalars['String']['output']>;
  customerNumber?: Maybe<Scalars['String']['output']>;
  type: CustomerUserAccountType;
};

export type CurrentUserAccountBillingAddress = {
  __typename?: 'CurrentUserAccountBillingAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type CurrentUserContact = {
  __typename?: 'CurrentUserContact';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CurrentUserError = {
  __typename?: 'CurrentUserError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CurrentUserResponse = {
  __typename?: 'CurrentUserResponse';
  data?: Maybe<CurrentUser>;
  errors?: Maybe<CurrentUserError>;
};

export type CurrentUserSite = {
  __typename?: 'CurrentUserSite';
  address: CurrentUserSiteAddress;
  batteries?: Maybe<Array<CurrentUserSiteBattery>>;
  id: Scalars['String']['output'];
  options: CurrentUserSiteOptions;
  previousYearGridPurchase?: Maybe<CurrentUserSitePreviousYearPurchase>;
};

export type CurrentUserSiteAddress = {
  __typename?: 'CurrentUserSiteAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type CurrentUserSiteBattery = {
  __typename?: 'CurrentUserSiteBattery';
  id: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
};

export type CurrentUserSiteOptions = {
  __typename?: 'CurrentUserSiteOptions';
  hasBatteries: Scalars['Boolean']['output'];
  hasChargers: Scalars['Boolean']['output'];
  hasHeatPumps: Scalars['Boolean']['output'];
  hasOldChargers: Scalars['Boolean']['output'];
  isAllowedToCreateContract: Scalars['Boolean']['output'];
};

export type CurrentUserSitePreviousYearPurchase = {
  __typename?: 'CurrentUserSitePreviousYearPurchase';
  aggregation?: Maybe<Scalars['Float']['output']>;
  unit: CurrentUserSitePreviousYearPurchaseUnit;
};

export enum CurrentUserSitePreviousYearPurchaseUnit {
  Kwh = 'KWH'
}

export enum CustomerSalutation {
  Mr = 'MR',
  Ms = 'MS'
}

export enum CustomerUserAccountType {
  BusinessCustomer = 'BUSINESS_CUSTOMER',
  PrivateCustomer = 'PRIVATE_CUSTOMER'
}

export type EnergyProvider = {
  __typename?: 'EnergyProvider';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GetSupplyAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  streetName?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createExistingCustomerOrder: Scalars['Boolean']['output'];
  createNewCustomerOrder: Scalars['Boolean']['output'];
  createProductConfigurations: ProductConfigurations;
};


export type MutationCreateExistingCustomerOrderArgs = {
  order: CreateExistingCustomerOrderInput;
};


export type MutationCreateNewCustomerOrderArgs = {
  order: CreateNewCustomerOrderInput;
};


export type MutationCreateProductConfigurationsArgs = {
  productConfiguration: CreateProductConfigurationsInput;
};

export type ProductConfigurationBatteryOptimizationModuleAttributes = {
  __typename?: 'ProductConfigurationBatteryOptimizationModuleAttributes';
  dayAheadPriceBaseFeeGrossPerMonth: CurrencyProperty;
  dayAheadPriceBaseFeeNetPerMonth: CurrencyProperty;
  dayAheadPriceBenefitDetailsAmount: CurrencyProperty;
  dayAheadPriceTerminationPeriodOption: TerminationPeriod;
};

export type ProductConfigurationWithBatteryOptimizationModule = {
  __typename?: 'ProductConfigurationWithBatteryOptimizationModule';
  baseFeeGrossPerMonth: CurrencyProperty;
  batteryOptimizationModule: ProductConfigurationBatteryOptimizationModuleAttributes;
  expectedPaymentGrossPerMonth: CurrencyProperty;
  fixedCostsGrossPerMonth: CurrencyProperty;
  productEngineConfigUrl: Scalars['String']['output'];
  purchaseTariffGrossPerKwh: CurrencyProperty;
  terminationPeriodOption: TerminationPeriod;
};

export type ProductConfigurationWithoutBatteryOptimizationModule = {
  __typename?: 'ProductConfigurationWithoutBatteryOptimizationModule';
  baseFeeGrossPerMonth: CurrencyProperty;
  expectedPaymentGrossPerMonth: CurrencyProperty;
  fixedCostsGrossPerMonth: CurrencyProperty;
  productEngineConfigUrl: Scalars['String']['output'];
  purchaseTariffGrossPerKwh: CurrencyProperty;
  terminationPeriodOption: TerminationPeriod;
};

export type ProductConfigurations = {
  __typename?: 'ProductConfigurations';
  configurationWithBatteryOptimizationModule?: Maybe<ProductConfigurationWithBatteryOptimizationModule>;
  configurationWithoutBatteryOptimizationModule: ProductConfigurationWithoutBatteryOptimizationModule;
  isNotAllowedToIncludeBatteryOptimizationModuleDueToChargerOrHeatPump: Scalars['Boolean']['output'];
};

export enum ProviderChangeReason {
  FirstTimeOccupancy = 'FIRST_TIME_OCCUPANCY',
  Relocation = 'RELOCATION',
  SupplierChange = 'SUPPLIER_CHANGE'
}

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<CurrentUser>;
  energyProviders: Array<Maybe<EnergyProvider>>;
  supplyAddress: SupplyAddressModel;
};


export type QuerySupplyAddressArgs = {
  getSupplyAddressData?: InputMaybe<GetSupplyAddressInput>;
};

export type SupplyAddressModel = {
  __typename?: 'SupplyAddressModel';
  cities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  postalCode: Scalars['String']['output'];
  streets?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum TerminationPeriod {
  Termination_30DaysToEndOfMonth = 'TERMINATION_30_DAYS_TO_END_OF_MONTH'
}

export enum Unit {
  Month = 'MONTH',
  String = 'STRING'
}

export type UnitNumberProperty = {
  __typename?: 'UnitNumberProperty';
  unit: Unit;
  value: Scalars['Int']['output'];
};

export type CreateExistingCustomerOrderMutationVariables = Exact<{
  input: CreateExistingCustomerOrderInput;
}>;


export type CreateExistingCustomerOrderMutation = { __typename?: 'Mutation', createExistingCustomerOrder: boolean };

export type CreateNewCustomerOrderMutationVariables = Exact<{
  input: CreateNewCustomerOrderInput;
}>;


export type CreateNewCustomerOrderMutation = { __typename?: 'Mutation', createNewCustomerOrder: boolean };

export type CreateProductConfigurationsMutationVariables = Exact<{
  input: CreateProductConfigurationsInput;
}>;


export type CreateProductConfigurationsMutation = { __typename?: 'Mutation', createProductConfigurations: { __typename?: 'ProductConfigurations', isNotAllowedToIncludeBatteryOptimizationModuleDueToChargerOrHeatPump: boolean, configurationWithBatteryOptimizationModule?: { __typename?: 'ProductConfigurationWithBatteryOptimizationModule', terminationPeriodOption: TerminationPeriod, productEngineConfigUrl: string, expectedPaymentGrossPerMonth: { __typename?: 'CurrencyProperty', value: string, currency: Currency }, baseFeeGrossPerMonth: { __typename?: 'CurrencyProperty', currency: Currency, value: string }, purchaseTariffGrossPerKwh: { __typename?: 'CurrencyProperty', value: string, currency: Currency }, fixedCostsGrossPerMonth: { __typename?: 'CurrencyProperty', value: string, currency: Currency }, batteryOptimizationModule: { __typename?: 'ProductConfigurationBatteryOptimizationModuleAttributes', dayAheadPriceTerminationPeriodOption: TerminationPeriod, dayAheadPriceBaseFeeNetPerMonth: { __typename?: 'CurrencyProperty', value: string, currency: Currency }, dayAheadPriceBaseFeeGrossPerMonth: { __typename?: 'CurrencyProperty', value: string, currency: Currency }, dayAheadPriceBenefitDetailsAmount: { __typename?: 'CurrencyProperty', value: string, currency: Currency } } } | null, configurationWithoutBatteryOptimizationModule: { __typename?: 'ProductConfigurationWithoutBatteryOptimizationModule', terminationPeriodOption: TerminationPeriod, productEngineConfigUrl: string, expectedPaymentGrossPerMonth: { __typename?: 'CurrencyProperty', value: string, currency: Currency }, baseFeeGrossPerMonth: { __typename?: 'CurrencyProperty', currency: Currency, value: string }, purchaseTariffGrossPerKwh: { __typename?: 'CurrencyProperty', value: string, currency: Currency }, fixedCostsGrossPerMonth: { __typename?: 'CurrencyProperty', value: string, currency: Currency } } } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'CurrentUser', id: string, contact: { __typename?: 'CurrentUserContact', firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, mobile?: string | null }, account: { __typename?: 'CurrentUserAccount', companyName?: string | null, customerNumber?: string | null, type: CustomerUserAccountType, billingAddress: { __typename?: 'CurrentUserAccountBillingAddress', city?: string | null, country?: string | null, streetName?: string | null, streetNumber?: string | null, postalCode?: string | null } }, sites?: Array<{ __typename?: 'CurrentUserSite', id: string, address: { __typename?: 'CurrentUserSiteAddress', city?: string | null, country?: string | null, streetName?: string | null, streetNumber?: string | null, postalCode?: string | null }, previousYearGridPurchase?: { __typename?: 'CurrentUserSitePreviousYearPurchase', aggregation?: number | null, unit: CurrentUserSitePreviousYearPurchaseUnit } | null, options: { __typename?: 'CurrentUserSiteOptions', hasBatteries: boolean, isAllowedToCreateContract: boolean, hasChargers: boolean, hasHeatPumps: boolean, hasOldChargers: boolean }, batteries?: Array<{ __typename?: 'CurrentUserSiteBattery', id: string, serialNumber: string }> | null }> | null } | null };

export type GetEnergyProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnergyProvidersQuery = { __typename?: 'Query', energyProviders: Array<{ __typename?: 'EnergyProvider', id?: string | null, name?: string | null } | null> };

export type GetSupplyAddressQueryVariables = Exact<{
  postalCode: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSupplyAddressQuery = { __typename?: 'Query', supplyAddress: { __typename?: 'SupplyAddressModel', cities?: Array<string | null> | null, streets?: Array<string | null> | null, postalCode: string } };


export const CreateExistingCustomerOrderDocument = gql`
    mutation CreateExistingCustomerOrder($input: CreateExistingCustomerOrderInput!) {
  createExistingCustomerOrder(order: $input)
}
    `;
export type CreateExistingCustomerOrderMutationFn = Apollo.MutationFunction<CreateExistingCustomerOrderMutation, CreateExistingCustomerOrderMutationVariables>;

/**
 * __useCreateExistingCustomerOrderMutation__
 *
 * To run a mutation, you first call `useCreateExistingCustomerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExistingCustomerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExistingCustomerOrderMutation, { data, loading, error }] = useCreateExistingCustomerOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExistingCustomerOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateExistingCustomerOrderMutation, CreateExistingCustomerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExistingCustomerOrderMutation, CreateExistingCustomerOrderMutationVariables>(CreateExistingCustomerOrderDocument, options);
      }
export type CreateExistingCustomerOrderMutationHookResult = ReturnType<typeof useCreateExistingCustomerOrderMutation>;
export type CreateExistingCustomerOrderMutationResult = Apollo.MutationResult<CreateExistingCustomerOrderMutation>;
export type CreateExistingCustomerOrderMutationOptions = Apollo.BaseMutationOptions<CreateExistingCustomerOrderMutation, CreateExistingCustomerOrderMutationVariables>;
export const CreateNewCustomerOrderDocument = gql`
    mutation CreateNewCustomerOrder($input: CreateNewCustomerOrderInput!) {
  createNewCustomerOrder(order: $input)
}
    `;
export type CreateNewCustomerOrderMutationFn = Apollo.MutationFunction<CreateNewCustomerOrderMutation, CreateNewCustomerOrderMutationVariables>;

/**
 * __useCreateNewCustomerOrderMutation__
 *
 * To run a mutation, you first call `useCreateNewCustomerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCustomerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCustomerOrderMutation, { data, loading, error }] = useCreateNewCustomerOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewCustomerOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewCustomerOrderMutation, CreateNewCustomerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewCustomerOrderMutation, CreateNewCustomerOrderMutationVariables>(CreateNewCustomerOrderDocument, options);
      }
export type CreateNewCustomerOrderMutationHookResult = ReturnType<typeof useCreateNewCustomerOrderMutation>;
export type CreateNewCustomerOrderMutationResult = Apollo.MutationResult<CreateNewCustomerOrderMutation>;
export type CreateNewCustomerOrderMutationOptions = Apollo.BaseMutationOptions<CreateNewCustomerOrderMutation, CreateNewCustomerOrderMutationVariables>;
export const CreateProductConfigurationsDocument = gql`
    mutation CreateProductConfigurations($input: CreateProductConfigurationsInput!) {
  createProductConfigurations(productConfiguration: $input) {
    configurationWithBatteryOptimizationModule {
      expectedPaymentGrossPerMonth {
        value
        currency
      }
      baseFeeGrossPerMonth {
        currency
        value
      }
      purchaseTariffGrossPerKwh {
        value
        currency
      }
      terminationPeriodOption
      fixedCostsGrossPerMonth {
        value
        currency
      }
      productEngineConfigUrl
      batteryOptimizationModule {
        dayAheadPriceBaseFeeNetPerMonth {
          value
          currency
        }
        dayAheadPriceBaseFeeGrossPerMonth {
          value
          currency
        }
        dayAheadPriceBenefitDetailsAmount {
          value
          currency
        }
        dayAheadPriceTerminationPeriodOption
      }
    }
    configurationWithoutBatteryOptimizationModule {
      expectedPaymentGrossPerMonth {
        value
        currency
      }
      baseFeeGrossPerMonth {
        currency
        value
      }
      purchaseTariffGrossPerKwh {
        value
        currency
      }
      terminationPeriodOption
      fixedCostsGrossPerMonth {
        value
        currency
      }
      productEngineConfigUrl
    }
    isNotAllowedToIncludeBatteryOptimizationModuleDueToChargerOrHeatPump
  }
}
    `;
export type CreateProductConfigurationsMutationFn = Apollo.MutationFunction<CreateProductConfigurationsMutation, CreateProductConfigurationsMutationVariables>;

/**
 * __useCreateProductConfigurationsMutation__
 *
 * To run a mutation, you first call `useCreateProductConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductConfigurationsMutation, { data, loading, error }] = useCreateProductConfigurationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductConfigurationsMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductConfigurationsMutation, CreateProductConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductConfigurationsMutation, CreateProductConfigurationsMutationVariables>(CreateProductConfigurationsDocument, options);
      }
export type CreateProductConfigurationsMutationHookResult = ReturnType<typeof useCreateProductConfigurationsMutation>;
export type CreateProductConfigurationsMutationResult = Apollo.MutationResult<CreateProductConfigurationsMutation>;
export type CreateProductConfigurationsMutationOptions = Apollo.BaseMutationOptions<CreateProductConfigurationsMutation, CreateProductConfigurationsMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    id
    contact {
      firstName
      lastName
      email
      phone
      mobile
    }
    account {
      companyName
      customerNumber
      type
      billingAddress {
        city
        country
        streetName
        streetNumber
        postalCode
      }
    }
    sites {
      id
      address {
        city
        country
        streetName
        streetNumber
        postalCode
      }
      previousYearGridPurchase {
        aggregation
        unit
      }
      options {
        hasBatteries
        isAllowedToCreateContract
        hasChargers
        hasHeatPumps
        hasOldChargers
      }
      batteries {
        id
        serialNumber
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export function useGetCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<typeof useGetCurrentUserSuspenseQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetEnergyProvidersDocument = gql`
    query getEnergyProviders {
  energyProviders {
    id
    name
  }
}
    `;

/**
 * __useGetEnergyProvidersQuery__
 *
 * To run a query within a React component, call `useGetEnergyProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnergyProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnergyProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnergyProvidersQuery(baseOptions?: Apollo.QueryHookOptions<GetEnergyProvidersQuery, GetEnergyProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnergyProvidersQuery, GetEnergyProvidersQueryVariables>(GetEnergyProvidersDocument, options);
      }
export function useGetEnergyProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnergyProvidersQuery, GetEnergyProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnergyProvidersQuery, GetEnergyProvidersQueryVariables>(GetEnergyProvidersDocument, options);
        }
export function useGetEnergyProvidersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEnergyProvidersQuery, GetEnergyProvidersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEnergyProvidersQuery, GetEnergyProvidersQueryVariables>(GetEnergyProvidersDocument, options);
        }
export type GetEnergyProvidersQueryHookResult = ReturnType<typeof useGetEnergyProvidersQuery>;
export type GetEnergyProvidersLazyQueryHookResult = ReturnType<typeof useGetEnergyProvidersLazyQuery>;
export type GetEnergyProvidersSuspenseQueryHookResult = ReturnType<typeof useGetEnergyProvidersSuspenseQuery>;
export type GetEnergyProvidersQueryResult = Apollo.QueryResult<GetEnergyProvidersQuery, GetEnergyProvidersQueryVariables>;
export const GetSupplyAddressDocument = gql`
    query getSupplyAddress($postalCode: String!, $city: String) {
  supplyAddress(getSupplyAddressData: {postalCode: $postalCode, city: $city}) {
    cities
    streets
    postalCode
  }
}
    `;

/**
 * __useGetSupplyAddressQuery__
 *
 * To run a query within a React component, call `useGetSupplyAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplyAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplyAddressQuery({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useGetSupplyAddressQuery(baseOptions: Apollo.QueryHookOptions<GetSupplyAddressQuery, GetSupplyAddressQueryVariables> & ({ variables: GetSupplyAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplyAddressQuery, GetSupplyAddressQueryVariables>(GetSupplyAddressDocument, options);
      }
export function useGetSupplyAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplyAddressQuery, GetSupplyAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplyAddressQuery, GetSupplyAddressQueryVariables>(GetSupplyAddressDocument, options);
        }
export function useGetSupplyAddressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSupplyAddressQuery, GetSupplyAddressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSupplyAddressQuery, GetSupplyAddressQueryVariables>(GetSupplyAddressDocument, options);
        }
export type GetSupplyAddressQueryHookResult = ReturnType<typeof useGetSupplyAddressQuery>;
export type GetSupplyAddressLazyQueryHookResult = ReturnType<typeof useGetSupplyAddressLazyQuery>;
export type GetSupplyAddressSuspenseQueryHookResult = ReturnType<typeof useGetSupplyAddressSuspenseQuery>;
export type GetSupplyAddressQueryResult = Apollo.QueryResult<GetSupplyAddressQuery, GetSupplyAddressQueryVariables>;