import { Alert, Card, Checkbox, InputField, Typography } from '@sonnen/web-ui';
import { useContext } from 'react';
import Markdown from 'react-markdown';
import { AppContext } from '../../../AppContext/AppContext';
import { TValidationResult } from '../../../hooks/useValidation';
import { useTranslation } from '../../../i18n/i18n';
import { TFormValues, TPaymentDetails } from '../../../types';
import { formatIBAN, formatIbanWithCursorPosition } from '../../../utils/formatting';
import commonStyles from '../../Common.module.css';
import { ExternalLinkComponent } from '../../../utils/utils';
import HoneypotField from '../../../components/HoneypotField/HoneypotField';

const I18N_SCOPE = 'data.paymentDetails';

type PickedValues = Pick<
  TFormValues,
  'iban' | 'accountOwner' | 'paymentAuthorization' | 'termsAndConditions' | 'marketing'
>;

type PaymentDetailsProps = {
  values: PickedValues;
  onChange: (field: string, value: string | boolean | null) => void;
  validate: (fields?: string[] | undefined) => void;
  errors: TValidationResult<PickedValues>;
  setErrors: (errors: TValidationResult<TPaymentDetails>) => void;
};

export const PaymentDetails = ({
  values,
  onChange,
  validate,
  errors,
  setErrors,
}: PaymentDetailsProps) => {
  const { t } = useTranslation();
  const { accountOwner, iban, paymentAuthorization, marketing, termsAndConditions } = values;
  const { user } = useContext(AppContext);

  const onBlurHandler = (fieldName: string) => validate([fieldName]);

  const onChangeHandler = (field: keyof PickedValues | string, value: string | boolean) => {
    setErrors({ ...errors, [field]: '' });
    onChange(field, value);
  };

  return (
    <Card className={commonStyles.formSection} dataTestId="payment-details">
      <Typography.H3>{t(`${I18N_SCOPE}.sectionTitle`)}</Typography.H3>

      <Alert dataTestId="secure-info" variant="info" title={t(`${I18N_SCOPE}.secureInfoTitle`)}>
        {t(`${I18N_SCOPE}.secureInfo`)}
      </Alert>

      <InputField
        name="iban"
        label={t(`${I18N_SCOPE}.iban`)}
        value={formatIBAN(iban)}
        onChange={(field, value, e) => {
          formatIbanWithCursorPosition(e!);
          onChangeHandler(field, value);
        }}
        onBlur={onBlurHandler}
        error={errors.iban}
        dataTestId="iban"
        maxLength={42}
        expanded
      />

      <InputField
        name="accountOwner"
        label={t(`${I18N_SCOPE}.accountOwner`)}
        sublabel={t(`${I18N_SCOPE}.accountOwnerSublabel`)}
        value={accountOwner}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        error={errors.accountOwner}
        dataTestId="account-owner"
        expanded
      />

      <HoneypotField />

      <Checkbox
        name="paymentAuthorization"
        onChange={() => onChangeHandler('paymentAuthorization', !paymentAuthorization)}
        checked={paymentAuthorization}
        error={Boolean(errors.paymentAuthorization)}
        errorLabel={errors.paymentAuthorization}
        dataTestId="payment-authorization"
        clickableLabel
        className={commonStyles.checkBox}
      >
        <Typography.Body2>{t(`${I18N_SCOPE}.paymentAuthorization`)}</Typography.Body2>
      </Checkbox>

      <Checkbox
        name="termsAndConditions"
        onChange={() => onChangeHandler('termsAndConditions', !termsAndConditions)}
        checked={termsAndConditions}
        error={Boolean(errors.termsAndConditions)}
        errorLabel={errors.termsAndConditions}
        dataTestId="terms-and-conditions"
        clickableLabel
        className={commonStyles.checkBox}
      >
        <Typography.Body2>
          <Markdown components={{ a: ExternalLinkComponent }}>
            {t(`${I18N_SCOPE}.termsAndConditions`)}
          </Markdown>
        </Typography.Body2>
      </Checkbox>

      {!user && (
        <Checkbox
          name="marketing"
          onChange={() => onChange('marketing', !marketing)}
          checked={Boolean(marketing)}
          dataTestId="marketing"
          clickableLabel
          className={commonStyles.checkBox}
        >
          <Typography.Body2>{t(`${I18N_SCOPE}.marketing`)}</Typography.Body2>
        </Checkbox>
      )}
    </Card>
  );
};
