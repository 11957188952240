import DOMPurify from 'dompurify';
import {
  CreateCustomerOrderPaymentDetailsInput,
  CreateExistingCustomerOrderDeliveryDetailsInput,
  CreateExistingCustomerOrderInput,
  CreateExistingCustomerOrderPersonalDataInput,
  CreateNewCustomerOrderInput,
  CreateNewCustomerOrderPersonalDataInput,
  CurrentUser,
  ProductConfigurationWithBatteryOptimizationModule,
  ProductConfigurationWithoutBatteryOptimizationModule,
} from '../../graphql/generated';
import { TFormValues } from '../../types';
import {
  completeAddress,
  formatDateYYYYMMDD,
  getActualBillingAddress,
  getActualDeliveryStartDate,
  removeLeadingZero,
} from '../../utils/utils';

const getPersonalDataInput = <
  T = CreateExistingCustomerOrderPersonalDataInput | CreateNewCustomerOrderPersonalDataInput,
>(
  formValues: TFormValues,
  user: CurrentUser | null = null,
): T => {
  const { firstName, lastName, email, phone, companyName } = formValues.personalData;

  const personalDataInput: Partial<CreateNewCustomerOrderPersonalDataInput> = {
    phone: user?.contact.phone ?? `+49 ${removeLeadingZero(phone)}`,
    email,
    firstName,
    lastName,
  };

  if (companyName) personalDataInput.companyName = DOMPurify.sanitize(companyName);

  if (!user) {
    return {
      ...personalDataInput,
      salutation: formValues.personalData.salutation,
    } as T;
  }

  return personalDataInput as T;
};

const getOrderMutationInput = (
  formValues: TFormValues,
  configuration:
    | ProductConfigurationWithoutBatteryOptimizationModule
    | ProductConfigurationWithBatteryOptimizationModule,
  consumptionEstimated: number,
) => {
  const { productEngineConfigUrl } = configuration;
  const {
    interestedInHardwareOffer,
    confirmedCancellationDate,
    contractTerminated,
    deliveryAddress,
    billingAddress,
  } = formValues;

  const deliveryDetails: CreateExistingCustomerOrderDeliveryDetailsInput = {
    consumptionEstimated,
    contractTerminated: formValues.contractTerminated,
    meterId: formValues.meterId,
    productEngineConfigUrl,
    terminatedEnergyProviderName: null,
    terminatedEnergyProviderId: null,
    confirmedCancellationDate: null,
    deliveryStartDate: formatDateYYYYMMDD(getActualDeliveryStartDate(formValues)),
  };

  if (contractTerminated) {
    deliveryDetails.confirmedCancellationDate = formatDateYYYYMMDD(confirmedCancellationDate);
  } else {
    deliveryDetails.terminatedEnergyProviderName = formValues.terminatedEnergyProviderName ?? '';
    deliveryDetails.terminatedEnergyProviderId = formValues.terminatedEnergyProviderId ?? '';
  }

  const paymentDetails: CreateCustomerOrderPaymentDetailsInput = {
    iban: formValues.iban.replace(/\s/g, ''),
    accountOwner: formValues.accountOwner,
    workEmail: '',
  };

  return {
    deliveryAddress: completeAddress(deliveryAddress),
    deliveryDetails,
    paymentDetails,
    accepted: {
      paymentAuthorization: formValues.paymentAuthorization,
      termsAndConditions: formValues.termsAndConditions,
    },
    billingAddress: completeAddress(billingAddress),
    ...(interestedInHardwareOffer && {
      interestedInHardwareOffer: true,
    }),
  };
};

export const getOrderMutationInputNewCustomer = (
  formValues: TFormValues,
  configuration: ProductConfigurationWithoutBatteryOptimizationModule,
  consumptionEstimated: number,
  customBillingAddress: boolean,
  workEmail: string,
): CreateNewCustomerOrderInput => {
  const input = getOrderMutationInput(formValues, configuration, consumptionEstimated);
  const personalData = getPersonalDataInput<CreateNewCustomerOrderPersonalDataInput>(formValues);
  return {
    ...input,
    deliveryDetails: {
      ...input.deliveryDetails,
      providerChangeReason: formValues.providerChangeReason!,
    },
    personalData,
    billingAddress: getActualBillingAddress(formValues, customBillingAddress),
    accepted: { ...input.accepted, marketing: Boolean(formValues.marketing) },
    paymentDetails: { ...input.paymentDetails, workEmail },
  };
};

export const getOrderMutationInputExistingCustomer = (
  user: CurrentUser | null,
  formValues: TFormValues,
  configuration:
    | ProductConfigurationWithoutBatteryOptimizationModule
    | ProductConfigurationWithBatteryOptimizationModule,
  consumptionEstimated: number,
  batteryId: string | undefined,
  workEmail: string,
): CreateExistingCustomerOrderInput => {
  const input = getOrderMutationInput(formValues, configuration, consumptionEstimated);

  const personalData = getPersonalDataInput<CreateExistingCustomerOrderPersonalDataInput>(
    formValues,
    user,
  );

  const deliveryDetails: CreateExistingCustomerOrderDeliveryDetailsInput = {
    ...input.deliveryDetails,
    batteryId: batteryId || null,
  };

  const paymentDetails: CreateCustomerOrderPaymentDetailsInput = {
    ...input.paymentDetails,
    workEmail,
  };

  return {
    ...input,
    personalData,
    deliveryDetails,
    paymentDetails,
  };
};
