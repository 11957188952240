import { useLocation } from 'react-router-dom';
import { AppRoute } from '../constants';

export const usePathname = (): AppRoute => {
  const { pathname } = useLocation();

  const routes: string[] = Object.values(AppRoute);

  return routes.includes(pathname) ? (pathname as AppRoute) : AppRoute.ROOT;
};
