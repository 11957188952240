import { USPTypography } from '../USPTypography/USPTypography';
import styles from './CustomUSPCard.module.css';

type CustomUSPCardProps = {
  i18nScope: 'home.cardChart' | 'home.cardSmartMeter' | 'home.priceOptimizationTeaserCard';
  children?: React.ReactNode;
  dataTestId?: string;
};

export const CustomUSPCard = ({
  i18nScope,
  children,
  dataTestId = 'usp-card',
}: CustomUSPCardProps) => (
  <div className={styles.customUspCard} data-testid={dataTestId}>
    <div className={styles.text}>
      <USPTypography i18nScope={i18nScope} />
    </div>
    {children}
  </div>
);
