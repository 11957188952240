import { useAuth } from '@sonnen/web-utils/hooks';
import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { EnergyDynamicLogo } from '../components/EnergyDynamicLogo/EnergyDynamicLogo';
import Footer from '../components/Footer/Footer';
import { Logout } from '../components/Logout/Logout';
import MobileCta from '../components/MobileCta/MobileCta';
import { AppRoute, ROUTE_FLOW } from '../constants';
import { CurrentUser } from '../graphql/generated';
import { useCurrentUserData } from '../hooks/useCurrentUserData';
import { usePathname } from '../hooks/usePathname';
import { OfferContext } from '../OfferContext/OfferContext';
import { Confirmation } from '../Pages/Confirmation/Confirmation';
import DataEntry from '../Pages/DataEntry/DataEntry';
import DataReview from '../Pages/DataReview/DataReview';
import Home from '../Pages/Home/Home';
import { Login } from '../Pages/Login/Login';
import { PriceOptimization } from '../Pages/Modules/PriceOptimization/PriceOptimization';
import OfferCalculation from '../Pages/OfferCalculation/OfferCalculation';
import TopbarWithNameAndLogout from './TopbarWithNameAndLogout/TopbarWithNameAndLogout';
import CookieBanner from '../components/CookieBanner/CookieBanner';

export const AppRoutes = () => {
  useCurrentUserData();
  const { user: authenticatedUser } = useAuth<CurrentUser>();
  const { offer } = useContext(OfferContext);
  const pathname = usePathname();
  const navigate = useNavigate();

  const [openCookieBanner, setOpenCookieBanner] = useState<boolean>(false);

  useEffect(() => {
    if (authenticatedUser) navigate(ROUTE_FLOW[1]);
    if (!offer && pathname !== AppRoute.ROOT && pathname !== AppRoute.LOGIN)
      navigate(AppRoute.OFFER_CALCULATION);
  }, []);

  return (
    <div data-testid="app-routes">
      <TopbarWithNameAndLogout />

      {pathname !== AppRoute.ROOT && <EnergyDynamicLogo />}

      <Routes>
        {!authenticatedUser && <Route path={AppRoute.ROOT} element={<Home />} />}
        <Route path={AppRoute.LOGIN} element={<Login />} />
        <Route path={AppRoute.LOGOUT} element={<Logout />} />
        <Route path={AppRoute.OFFER_CALCULATION} element={<OfferCalculation />} />

        {offer ? (
          <>
            <Route path={AppRoute.PRICE_OPTIMIZATION} element={<PriceOptimization />} />
            <Route path={AppRoute.DATA_ENTRY} element={<DataEntry />} />
            <Route path={AppRoute.DATA_REVIEW} element={<DataReview />} />
            <Route path={AppRoute.ORDER_COMPLETE} element={<Confirmation />} />
          </>
        ) : (
          <Route path="*" element={<Home />} />
        )}
      </Routes>

      <Footer openCookieBanner={() => setOpenCookieBanner(!openCookieBanner)} />
      <CookieBanner open={openCookieBanner} setOpen={setOpenCookieBanner} />

      <MobileCta />
    </div>
  );
};
