import { Typography } from '@sonnen/web-ui';
import styles from './DataRow.module.css';

type DataRowProps = {
  dataTestId?: string;
  label: string;
  value: string;
};

export const DataRow = ({ label, value, dataTestId }: DataRowProps) => (
  <div className={styles.dataRow} data-testid={dataTestId}>
    <Typography.Label2 variant="secondary">{label}</Typography.Label2>
    <Typography.Body1>{value}</Typography.Body1>
  </div>
);
