// TODO: SON-52494 replace this local implementation with the one from @sonnen/web-utils
import { useEffect, useState } from 'react';
import {
  supportedLanguages,
  TranslationTools,
  SupportedLanguage,
  LocaleMap,
  TFunction,
  TranslationOptions,
  Translations,
} from './types';

const TRANSLATION_UPDATE_EVENT = 'translationupdate';
const LANGUAGE_CHANGE_EVENT = 'languagechange';

declare global {
  interface Window {
    Toolbox: {
      translations: Translations;
      language: SupportedLanguage;
    };
  }
}

const LOCALE_STORAGE_KEY = 'toolbox:language';
const DEFAULT_LANGUAGE: SupportedLanguage = 'de';

window.Toolbox = window.Toolbox || {
  language: window.navigator.language as SupportedLanguage,
  translations: {
    de: {},
    'en-GB': {},
  },
};

const getLocaleOfStringOrDefault = (locale: string): SupportedLanguage => {
  return (
    Object.values(supportedLanguages).find((supportedLanguage: string) =>
      supportedLanguage.startsWith(locale),
    ) || DEFAULT_LANGUAGE
  );
};

export const getLocale = (): SupportedLanguage => {
  const storageLang = localStorage && localStorage.getItem(LOCALE_STORAGE_KEY);
  const browserLang = navigator.language === 'en-GB' ? 'en-GB' : navigator.language.split('-')[0];
  return getLocaleOfStringOrDefault(storageLang || browserLang);
};

const getEntry = (key: string, entry: LocaleMap) => entry[key];

const writeEntries = (
  [key, value]: [key: string, value: LocaleMap | string],
  entries: LocaleMap,
) => {
  const newEntries = entries;
  if (typeof value === 'object') {
    newEntries[key] = newEntries[key] || {};
    Object.entries(value).forEach(([nestedKey, nestedValue]) => {
      if (nestedValue) writeEntries([nestedKey, nestedValue], newEntries[key] as LocaleMap);
    });
  } else if (value) {
    newEntries[key] = value;
  }
  return newEntries;
};

/**
 * In order to add translations to the current ones, use this function.
 * @param newTranslations The new translations to be merged with the existing ones.
 * All locales are required.
 * @returns The updated list with all translations.
 */
export const addTranslations = (newTranslations: Translations) => {
  const nextTranslations = window.Toolbox.translations;
  Object.entries(newTranslations).forEach(([locale, values]) =>
    writeEntries([locale, values], nextTranslations),
  );
  window.Toolbox.translations = nextTranslations;
  dispatchEvent(new Event(TRANSLATION_UPDATE_EVENT));
  return nextTranslations;
};

export const importTranslations = async (incoming: Record<string, () => Promise<unknown>>) => {
  const translations: Partial<Translations> = {};
  const promises = Object.entries(incoming).map(async ([filepath, loader]) => {
    const data = await loader();
    const locale = filepath.split('/').pop()?.split('.')[0] as SupportedLanguage;
    translations[locale] = data as LocaleMap;
  });
  await Promise.all(promises);

  addTranslations(translations as Translations);
};

/**
 * Hook to get the current language and translate strings.
 * @returns {TranslationTools} The translation tools.
 */
export const useTranslation = (): TranslationTools => {
  const [language, setLanguage] = useState<SupportedLanguage>(getLocale());
  const [translations, setTranslations] = useState<Translations>(window.Toolbox.translations);

  const t: TFunction = (key: string, options?: TranslationOptions): string => {
    const keys = key.split('.');
    let translationForKey = getEntry(keys[0], translations[language]);

    while (typeof translationForKey === 'object' && keys.length > 1) {
      keys.shift();
      translationForKey = getEntry(keys[0], translationForKey);
    }

    if (!translationForKey) return options?.default ?? key;

    if (options && typeof translationForKey === 'string') {
      return translationForKey.replace(
        /{{\s*(\w+)\s*}}/g,
        (_match: string, option: string) => options[option] || option,
      );
    }

    return translationForKey.toString();
  };

  const changeLanguage = (newLanguage: SupportedLanguage) => {
    window.Toolbox.language = newLanguage;
    dispatchEvent(new Event(LANGUAGE_CHANGE_EVENT));
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(window.Toolbox.language);
      localStorage.setItem(LOCALE_STORAGE_KEY, window.Toolbox.language);
    };
    const onTranslationUpdate = () => {
      setTranslations({ ...window.Toolbox.translations });
    };

    window.addEventListener(LANGUAGE_CHANGE_EVENT, handleLanguageChange);
    window.addEventListener(TRANSLATION_UPDATE_EVENT, onTranslationUpdate);
    return () => {
      window.removeEventListener(LANGUAGE_CHANGE_EVENT, handleLanguageChange);
      window.removeEventListener(TRANSLATION_UPDATE_EVENT, onTranslationUpdate);
    };
  }, []);

  return {
    t,
    language,
    changeLanguage,
  };
};
