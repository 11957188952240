import { Card } from '@sonnen/web-ui';
import classNames from 'classnames';
import { TPriceCard } from '../../types';
import { PriceCards } from '../PriceCards/PriceCards';
import { USPTypography } from '../USPTypography/USPTypography';
import styles from './USPCard.module.css';

type USPCardProps = {
  children: React.ReactNode;
  dataTestId?: string;
  cards?: TPriceCard[];
  paddingSize?: 'sm' | 'md';
};

const USPCard = ({
  cards,
  children,
  dataTestId = 'usp-card',
  paddingSize = 'md',
}: USPCardProps) => (
  <Card paddingSize={paddingSize}>
    <div className={classNames(styles.uspCard)} data-testid={dataTestId}>
      <USPTypography i18nScope="home.cardOptimization" />
      {cards && cards.length > 0 && <PriceCards cards={cards} />}
      {children}
    </div>
  </Card>
);

export default USPCard;
