import { Toggle } from '@sonnen/web-ui';
import { useContext } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import { ModuleName } from '../../types';
import { moduleAvailable } from '../../utils/utils';

type ModuleCardProps = {
  name: ModuleName;
};

export const ModuleToggle = ({ name }: ModuleCardProps) => {
  const { t } = useTranslation();
  const { offer, offerLoading } = useContext(OfferContext);
  const { modules, toggleModule } = useContext(AppContext);
  const selected = modules.has(name);
  const available = moduleAvailable(offer, name);

  const onChange = () => toggleModule(name);

  return (
    <Toggle
      disabled={offerLoading || !available}
      value={selected && available}
      onChange={onChange}
      dataTestId={`${name}-toggle`}
      ariaLabel={t(`modules.${selected ? 'included' : 'excluded'}`)}
    />
  );
};
