import { Alert, Button, Typography } from '@sonnen/web-ui';
import { useAuth } from '@sonnen/web-utils/hooks';
import { memo } from 'react';
import CheckmarkLines from '../../../components/CheckmarkLines/CheckmarkLines';

import { CurrentUser } from '../../../graphql/generated';
import { useTranslation } from '../../../i18n/i18n';
import styles from './LoginCard.module.css';

const LoginCard = () => {
  const { t } = useTranslation();
  const { login, error } = useAuth<CurrentUser>();

  return (
    <div className={styles.loginCard} data-testid="login-card">
      <div className={styles.content}>
        <span>
          <Typography.H2 variant="on-colour">{t('login.customerTitle')}</Typography.H2>
          <CheckmarkLines list={t('login.customerBulletPoints').split('\n')} variant="light" />
        </span>

        <span className={styles.loginBtn}>
          {error && (
            <Alert variant="error" dataTestId="error-notification">
              {t('loginError')}
            </Alert>
          )}

          <Button
            label={t('loginButtonLabel')}
            dataTestId="login-btn"
            variant="secondary"
            iconRight="ExternalLink"
            onClick={login}
            expanded
          />
        </span>
      </div>
    </div>
  );
};

export default memo(LoginCard);
