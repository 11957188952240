import { useAuth } from '@sonnen/web-utils/hooks';
import { useEffect } from 'react';
import { getLogoutRedirectUri } from '../../utils/utils';

export const Logout = () => {
  const { logout } = useAuth();
  const redirectUri = getLogoutRedirectUri();

  useEffect(() => logout(redirectUri), [logout]);

  return null;
};
