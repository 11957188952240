import { Checkbox, Typography } from '@sonnen/web-ui';
import { useState } from 'react';
import { useTranslation } from '../../i18n/i18n';
import { USPTypography } from '../USPTypography/USPTypography';
import styles from './BenefitCard.module.css';

type BenefitCardProps = {
  onClick: () => void;
};

const BenefitCard = ({ onClick }: BenefitCardProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  return (
    <div className={styles.benefitCard} data-testid="benefit-card">
      <div className={styles.content}>
        <USPTypography variant="on-colour" i18nScope="home.priceOptimizationTeaserCard" />
      </div>

      <div className={styles.hardwareCta}>
        <div>
          <Checkbox
            name="hardware-offer"
            dataTestId="hardware-offer"
            onChange={() => {
              setChecked(!checked);
              onClick();
            }}
            checked={checked}
            clickableLabel
          >
            <Typography.H4>{t('home.priceOptimizationTeaserCard.checkbox')}</Typography.H4>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default BenefitCard;
