import { useState, useEffect } from 'react';
import { useGetSupplyAddressQuery } from '../graphql/generated';
import { TAddress } from '../types';
import { nullOrString, getNonNullValues } from '../utils/utils';
import { postcodeValidator } from '../utils/validation';

export const useAddressService = (defaultAddress: TAddress) => {
  const [address, setAddress] = useState<TAddress>(defaultAddress);
  const [selectableCities, setSelectableCities] = useState<string[]>([]);
  const invalidPostalCode = !postcodeValidator(address.postalCode);

  const {
    data: streetData,
    loading: streetLoading,
    error: streetError,
    refetch,
  } = useGetSupplyAddressQuery({
    variables: { postalCode: address.postalCode ?? '', city: address.city },
    skip: invalidPostalCode || !address.city,
  });

  const {
    data: cityData,
    loading: cityLoading,
    error: cityError,
  } = useGetSupplyAddressQuery({
    variables: { postalCode: address.postalCode ?? '' },
    skip: invalidPostalCode,
  });

  const error = cityError || streetError;
  const cityStreets = cityData?.supplyAddress.streets || [];
  const streets = cityStreets.length > 0 ? cityStreets : streetData?.supplyAddress.streets || [];
  const { cities } = cityData?.supplyAddress || { cities: [] };

  useEffect(() => {
    setAddress(defaultAddress);
  }, [defaultAddress]);

  useEffect(() => {
    if (!streetData || error) return;

    const newAddress = { ...address };

    if (!streets?.map(nullOrString).includes(address.streetName || '')) newAddress.streetName = '';

    setAddress(newAddress);
  }, [streetData]);

  useEffect(() => {
    if (cities?.[0] && !cities?.map(nullOrString).includes(address.city || '')) {
      const newAddress = { ...address, city: cities[0] };
      setAddress(newAddress);
    }

    if (cities && cities.length > 1) setSelectableCities(getNonNullValues(cities));
  }, [cities]);

  useEffect(() => {
    if (!address.postalCode) setSelectableCities([]);
    refetch?.({ postalCode: address.postalCode, city: address.city });
  }, [address.city, address.postalCode]);

  return {
    cities: selectableCities,
    streets: streets ? streets.map(nullOrString) : [],
    address,
    setAddress,
    cityLoading,
    streetLoading,
    error,
    refetch,
  };
};
