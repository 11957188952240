import { Button, Modal, Typography } from '@sonnen/web-ui';
import { useTranslation } from '../../i18n/i18n';
import styles from './NoTariffPurchaseModal.module.css';
import { parseHtmlTags } from '@sonnen/web-utils';

const I18N_SCOPE = 'home.contractModal';

type ContractModalProps = {
  siteName: string | undefined;
  switchSite?: () => void;
};

const ContractModal = ({ siteName, switchSite }: ContractModalProps) => {
  const { t } = useTranslation();

  const goToWebsite = () => {
    window.location.href = 'https://sonnen.de';
  };

  const messageWithSiteName = parseHtmlTags(
    t(`${I18N_SCOPE}.explanation`, { siteName: ` ${siteName}` }),
  );

  return (
    <Modal dataTestId="no-tariff-purchase-modal" className={styles.noTariffPurchaseModal}>
      <div className={styles.modalContent}>
        <Typography.H3>{t(`${I18N_SCOPE}.title`)}</Typography.H3>

        <span className={styles.explanation}>
          <Typography.Body2 variant="secondary">{messageWithSiteName}</Typography.Body2>
        </span>

        {switchSite && (
          <div className={styles.mainBtn}>
            <Button
              onClick={switchSite}
              label={t(`${I18N_SCOPE}.changeSiteBtn`)}
              dataTestId="change-site-btn"
              expanded
            />
          </div>
        )}

        <Button
          onClick={goToWebsite}
          label={t(`${I18N_SCOPE}.toWebsiteBtn`)}
          dataTestId="return-to-website-btn"
          variant={switchSite ? 'secondary' : 'primary'}
          expanded
        />
      </div>
    </Modal>
  );
};

export default ContractModal;
