import { Alert, InputField, Select, Spinner, Typography } from '@sonnen/web-ui';
import classNames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import { useValidation } from '../../hooks/useValidation';
import { useTranslation } from '../../i18n/i18n';
import { TAddress } from '../../types';
import { getNonNullValues, stringToOptionType } from '../../utils/utils';
import { commonFieldValidator, postcodeValidator } from '../../utils/validation';
import { DataRow } from '../DataRow/DataRow';

import { AppContext } from '../../AppContext/AppContext';
import { useAddressService } from '../../hooks/useAddressService';
import styles from './AddressSection.module.css';

const I18N_SCOPE = 'data.address';

type AddressSectionProps = {
  defaultValue: TAddress;
  name: 'deliveryAddress' | 'billingAddress';
  notification?: string;
  onChange: (name: string, address: TAddress) => void;
  readonlyFields?: (keyof TAddress)[];
  revalidate?: boolean;
};

const AddressSection = ({
  defaultValue,
  name,
  notification,
  onChange: onChangeProp,
  readonlyFields = [],
  revalidate,
}: AddressSectionProps) => {
  const { t } = useTranslation();
  const { setFormValues, formValues } = useContext(AppContext);
  const { address, setAddress, error, cities, cityLoading, streetLoading, streets } =
    useAddressService(defaultValue);

  const validationSchema = useMemo(
    () => ({
      postalCode: {
        required: !readonlyFields.includes('postalCode'),
        message: t(`${I18N_SCOPE}.postalCodeError`),
        validator: postcodeValidator,
      },
      city: {
        required: !readonlyFields.includes('city'),
        message: t(`${I18N_SCOPE}.cityError`),
      },
      streetName: {
        required: !readonlyFields.includes('streetName'),
        message: t(`${I18N_SCOPE}.streetNameError`),
      },
      streetNumber: {
        required: !readonlyFields.includes('streetNumber'),
        message: t(`${I18N_SCOPE}.streetNumberError`),
        validator: commonFieldValidator,
      },
    }),
    [t],
  );

  const [validate, { errors, setErrors }] = useValidation<TAddress>({
    values: address,
    schema: validationSchema,
    lazy: true,
  });

  const onBlur = (field: string) => {
    validate([field]);
    onChangeProp(name, address);
  };

  const onChange = (field: string, value: string) => {
    const newAddress = { ...address, [field]: value };

    setFormValues({
      ...formValues,
      ...(name === 'deliveryAddress' && { deliveryAddress: newAddress }),
      ...(name === 'billingAddress' && { billingAddress: newAddress }),
    });
    setAddress?.(newAddress);
    validate([field], newAddress);
  };

  const onCityChange = (_: string, value: string) => {
    const newAddress = { ...address, city: value, streetName: '' };
    setAddress?.(newAddress);
    validate(['city'], newAddress);
    setErrors({ ...errors, streetName: '', streetNumber: '' });
  };

  const onFocus = (field: string) => setErrors({ ...errors, [field]: '' });

  const onComponentBlur = () => {
    onChangeProp(name, address);
  };

  useEffect(() => {
    if (revalidate) validate();
  }, [revalidate]);

  const onPostalCodeChange = (_name: string, value: string) => {
    setAddress?.({ ...address, streetName: '', streetNumber: '', postalCode: value });
  };

  const displayReadonlyCity = !!streets?.length && address.postalCode && address.city;

  return (
    <div onBlur={onComponentBlur} data-testid={`${name}-section`} className={styles.addressSection}>
      <Typography.H3>{t(`${I18N_SCOPE}.${name}SectionTitle`)}</Typography.H3>

      {notification && (
        <Alert variant="warning" dataTestId="address-notification">
          {notification}
        </Alert>
      )}

      {readonlyFields.includes('postalCode') ? (
        <DataRow
          dataTestId="postal-code-row"
          label={t(`${I18N_SCOPE}.postalCode`)}
          value={address.postalCode ?? ''}
        />
      ) : (
        <InputField
          dataTestId="postal-code"
          name="postalCode"
          label={t(`${I18N_SCOPE}.postalCode`)}
          value={address.postalCode ?? ''}
          onChange={onPostalCodeChange}
          onBlur={onBlur}
          onFocus={onFocus}
          error={errors?.postalCode}
          autoComplete="on"
          expanded
        />
      )}

      {!readonlyFields.includes('city') && !!cities?.length && cities.length > 1 ? (
        <Select
          name="city"
          value={stringToOptionType(address.city || '')}
          options={cities.map(stringToOptionType) || []}
          dataTestId="city-select"
          label={t(`${I18N_SCOPE}.city`)}
          searchable={false}
          clearable={false}
          disabled={!cities?.length}
          loading={cityLoading}
          error={errors?.city}
          onChange={onCityChange}
          onBlur={onBlur}
        />
      ) : (
        <>
          {cityLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              {displayReadonlyCity && (
                <DataRow
                  dataTestId="city-row"
                  label={t(`${I18N_SCOPE}.city`)}
                  value={address.city || ''}
                />
              )}
            </>
          )}
        </>
      )}

      <div
        className={classNames(styles.streetAndNumber, {
          [styles.readonly]: readonlyFields.includes('streetNumber'),
        })}
      >
        <div className={styles.street}>
          {readonlyFields.includes('streetName') ? (
            <DataRow
              dataTestId="street-row"
              label={t(`${I18N_SCOPE}.streetName`)}
              value={address.streetName || ''}
            />
          ) : (
            <Select
              name="streetName"
              value={stringToOptionType(address.streetName || '')}
              options={getNonNullValues(streets)?.map(stringToOptionType) || []}
              dataTestId="street-select"
              label={t(`${I18N_SCOPE}.streetName`)}
              clearable={false}
              loading={!streets?.length && streetLoading}
              disabled={!streets?.length && !streetLoading}
              error={errors?.streetName}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        </div>

        <div>
          {readonlyFields.includes('streetNumber') ? (
            <div className={styles.readonlyNumber}>
              <DataRow
                dataTestId="street-number-row"
                label={t(`${I18N_SCOPE}.streetNumber`)}
                value={address.streetNumber || ''}
              />
            </div>
          ) : (
            <InputField
              dataTestId="street-number"
              name="streetNumber"
              label={t(`${I18N_SCOPE}.streetNumber`)}
              value={address.streetNumber || ''}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={errors?.streetNumber}
              className={styles.number}
              maxLength={10}
              expanded
            />
          )}
        </div>
      </div>

      {error && !streetLoading && !cityLoading && (
        <Alert variant="error" dataTestId="error-notification">
          {`${t(`${I18N_SCOPE}.error`)}`}
        </Alert>
      )}
    </div>
  );
};

export default AddressSection;
