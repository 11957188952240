export const supportedLanguages = ['de', 'en-GB'] as const;
export type SupportedLanguage = (typeof supportedLanguages)[number];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TranslationOptions = Record<string, any>;
export type TFunction = (key: string, options?: TranslationOptions) => string;

/**
 * Functions to handle translations.
 * @property {TFunction} t Function to translate strings.
 * @property {SupportedLanguage} language The currently selected language.
 * @property {Function} changeLanguage Function to change the current language.
 */
export type TranslationTools = {
  t: TFunction;
  language: SupportedLanguage;
  changeLanguage: (newLanguage: SupportedLanguage) => void;
};

export type LocaleMap = {
  [key: string]: LocaleMap | string | undefined;
};

export type Translations = { [key in SupportedLanguage]: LocaleMap };
