import { useNavigate } from 'react-router-dom';

export const useTopScrollNavigate = () => {
  const navigate = useNavigate();

  const onNavigate = (path: string, state?: { state: string }) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path, state);
  };

  return onNavigate;
};
