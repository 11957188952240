import { InputField, Select, Spinner, Typography } from '@sonnen/web-ui';
import classNames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../../../AppContext/AppContext';
import { AppRoute } from '../../../../constants';
import { useAddressService } from '../../../../hooks/useAddressService';
import { usePathname } from '../../../../hooks/usePathname';
import { useValidation } from '../../../../hooks/useValidation';
import { useTranslation } from '../../../../i18n/i18n';
import { OfferContext } from '../../../../OfferContext/OfferContext';
import { TAddress } from '../../../../types';
import { removeEmptySpaces, stringToOptionType } from '../../../../utils/utils';
import styles from './AdditionalAddress.module.css';
import { commonFieldValidator } from '../../../../utils/validation';

const I18N_SCOPE = 'data.address';

const AdditionalAddress = () => {
  const { t } = useTranslation();
  const { formValues, setFormValues } = useContext(AppContext);
  const {
    offerError,
    productConfigurationsInput: productConfigurationInput,
    setProductConfigurationsInput,
  } = useContext(OfferContext);
  const { address, cities, streets, cityLoading, streetLoading, refetch } = useAddressService(
    formValues.deliveryAddress,
  );
  const { city, streetName, streetNumber, postalCode } = address;
  const multipleCities = cities.length > 1;
  const pathname = usePathname();
  const homePage = pathname === AppRoute.ROOT;

  const validationSchema = useMemo(
    () => ({
      city: {
        required: multipleCities,
        message: t(`${I18N_SCOPE}.cityError`),
      },
      streetName: {
        required: true,
        message: t(`${I18N_SCOPE}.streetNameError`),
      },
      streetNumber: {
        required: true,
        message: t(`${I18N_SCOPE}.streetNumberError`),
        validator: commonFieldValidator,
      },
    }),
    [t],
  );

  const [validate, { errors, setErrors }] = useValidation<TAddress>({
    values: address,
    schema: validationSchema,
    lazy: true,
  });

  useEffect(() => {
    if (offerError) refetch({ postalCode, city: '' });
  }, []);

  const onChange = (field: string, value: string) => {
    const deliveryAddress = { ...address, [field]: removeEmptySpaces(value) };
    setFormValues({ ...formValues, deliveryAddress });
  };

  const onCityChange = (_: string, value: string) => {
    if (value !== city) {
      const deliveryAddress = {
        ...address,
        city: value,
        streetName: '',
        streetNumber: '',
      };

      setFormValues({ ...formValues, deliveryAddress });
      setProductConfigurationsInput({ ...productConfigurationInput, deliveryAddress });
      setErrors({ ...errors, streetName: '', streetNumber: '' });
    }
  };

  const onBlur = (field: string) => validate([field]);

  return (
    <div
      className={classNames(styles.additionalAddress, { [styles.columnLayout]: homePage })}
      data-testid="additional-address-section"
    >
      <Typography.Label1 variant="secondary">{t('offer.additionalAddressTitle')}</Typography.Label1>

      {cityLoading ? (
        <div className={styles.spinner}>
          <Spinner size="md" />
        </div>
      ) : (
        <div className={classNames(styles.address, { [styles.multipleCities]: multipleCities })}>
          {multipleCities && (
            <Select
              name="city"
              dataTestId="city-select"
              value={stringToOptionType(city || '')}
              options={cities.map(stringToOptionType)}
              label={t(`${I18N_SCOPE}.city`)}
              searchable={false}
              clearable={false}
              disabled={!cities.length}
              error={errors.city}
              onChange={onCityChange}
              onBlur={onBlur}
            />
          )}
          <div className={styles.stackIndex} data-testid="index-order">
            <Select
              name="streetName"
              dataTestId="street-select"
              value={stringToOptionType(streetName || '')}
              options={streets.map(stringToOptionType)}
              label={t(`${I18N_SCOPE}.streetName`)}
              clearable={false}
              loading={streetLoading}
              disabled={!streets.length || streetLoading}
              error={errors.streetName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>

          <InputField
            name="streetNumber"
            dataTestId="street-number"
            label={t(`${I18N_SCOPE}.streetNumber`)}
            value={streetNumber || ''}
            onChange={onChange}
            onBlur={onBlur}
            error={errors.streetNumber}
            className={styles.number}
            maxLength={10}
            expanded
          />
        </div>
      )}
    </div>
  );
};

export default AdditionalAddress;
