import { useMemo } from 'react';
import {
  EARLIEST_DELIVERY_START,
  EMPTY_DELIVERY_ADDRESS,
  modulePropertyByName,
} from '../constants';
import {
  CurrentUser,
  CurrentUserAccountBillingAddress,
  CurrentUserContact,
  CurrentUserSite,
  CustomerSalutation,
  CustomerUserAccountType,
  ProductConfigurations,
  ProviderChangeReason,
} from '../graphql/generated';
import { useTranslation } from '../i18n/i18n';
import {
  ModuleName,
  SelectOptionType,
  TAddress,
  TCompleteAddress,
  TDeliveryDetails,
  TFormValues,
} from '../types';

const { FirstTimeOccupancy, Relocation } = ProviderChangeReason;

/**
 * @param date the date to which to add the given number of days.
 * @param days the number of days to add to the given date. Default is 20 days.
 * @returns a new date object with the given number of days added.
 */
export const inDays = (days: number, date: Date = new Date()): Date => {
  const zeroedDate = new Date(date.toISOString().split('T')[0]);
  return new Date(zeroedDate.getTime() + days * 24 * 60 * 60 * 1000);
};

export const in20Days = (date = new Date()): Date => inDays(20, date);

const sonnenDeliveryStartOrDefault = (defaultDate: Date): Date => {
  return defaultDate > EARLIEST_DELIVERY_START ? defaultDate : EARLIEST_DELIVERY_START;
};

export const getActualDeliveryStartDate = (
  values: Pick<
    TDeliveryDetails,
    | 'confirmedCancellationDate'
    | 'contractTerminated'
    | 'deliveryStartDate'
    | 'providerChangeReason'
  >,
): Date => {
  const { contractTerminated, confirmedCancellationDate, deliveryStartDate, providerChangeReason } =
    values;
  const in20DaysResult = in20Days();
  const in20DaysTime = in20DaysResult.getTime();

  if (contractTerminated) {
    if (!confirmedCancellationDate) return sonnenDeliveryStartOrDefault(in20DaysResult);
    if (confirmedCancellationDate.getTime() < in20DaysTime)
      return sonnenDeliveryStartOrDefault(in20DaysResult);
    const returnDate = new Date(confirmedCancellationDate.getTime() + 24 * 60 * 60 * 1000);
    return sonnenDeliveryStartOrDefault(returnDate);
  }

  if (providerChangeReason && [Relocation, FirstTimeOccupancy].includes(providerChangeReason)) {
    return sonnenDeliveryStartOrDefault(inDays(0));
  }
  if (!deliveryStartDate || deliveryStartDate.getTime() < in20DaysTime) {
    return sonnenDeliveryStartOrDefault(in20DaysResult);
  }
  return sonnenDeliveryStartOrDefault(deliveryStartDate);
};

export const localeDateString = (date: Date) =>
  date.toLocaleDateString(navigator.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

export const getRedirectUri = () => {
  const ticketNumber = window.location.pathname.match(/SON-\d+/)?.[0] || '';

  const url = new URL(window.location.origin);
  url.pathname = `${ticketNumber}/login`;
  return url.toString();
};

export const getLogoutRedirectUri = () => {
  const env = process.env.VITE_ENV;
  let environment = '';
  if (env === 'staging') environment = 'staging.';
  if (env === 'development') environment = 'dev.';
  return `https://tariffs.${environment}sonnen.de`;
};

export const getFullName = (contact: Pick<CurrentUserContact, 'firstName' | 'lastName'>) => {
  const { firstName = '', lastName = '' } = contact || {};
  return [firstName, lastName].filter(Boolean).join(' ');
};

/**
 * @param date Date object.
 * @returns a string with the format yyyy-mm-dd or empty string.
 */
export const formatDateYYYYMMDD = (date?: Date) => {
  if (!date || Object.prototype.toString.call(date) !== '[object Date]') return '';

  return date.toISOString().split('T')[0];
};

/**
 * Returns either selected site address, the user's billing address or an empty value object.
 * @param user
 * @param selectedSite
 * @returns a delivery address object.
 */
export const getValidDeliveryAddress = (
  user: CurrentUser | null,
  selectedSite?: CurrentUserSite | null,
): TAddress => {
  const target = selectedSite?.address || user?.account?.billingAddress || EMPTY_DELIVERY_ADDRESS;

  return {
    city: target.city || '',
    country: target.country || '',
    postalCode: target.postalCode || '',
    streetName: target.streetName || '',
    streetNumber: target.streetNumber || '',
  };
};

/**
 * @param value number
 * @param precision number
 * @returns a rounded up or down number with decimals according to the precision passed
 */
export function roundWithPrecision(value: number, precision?: number): number {
  const multiplier = 10 ** (precision ?? 0);
  return Math.round(value * multiplier) / multiplier;
}

export const siteToOptionType = (
  site: CurrentUserSite,
  batteryLabel?: string,
  contractLabel?: string,
): SelectOptionType => {
  const serialNumber = site.batteries?.[0]?.serialNumber;
  const serialNumberString = serialNumber ? `${serialNumber} - ` : '';
  const battery = site.options.hasBatteries ? batteryLabel : '';
  const contract = site.options.isAllowedToCreateContract ? '' : contractLabel;
  const additionalText = battery && contract ? `${battery} & ${contract}` : battery || contract;

  return {
    label: `${serialNumberString}${site.address.streetName} ${site.address.streetNumber}, ${site.address.postalCode} ${site.address.city}`,
    value: site.id,
    additionalText,
  };
};

export const stringToOptionType = (value: string): SelectOptionType => ({ label: value, value });

/**
 * Returns a string or an empty string if the value is null.
 * @param value
 */
export const nullOrString = (value: string | null): string => value ?? '';

/**
 * Removes null values from an array
 * @param values
 */
export const getNonNullValues = (
  values: (string | null)[] | never[] | null | undefined,
): string[] => {
  const okValues = values?.filter((value) => value !== null) || [];
  return okValues.map(nullOrString);
};

/**
 * Returns the corresponding salutation object
 * @param salutationOptions array of salutations with values and translated labels
 * @param checkValue value to be found
 */
export const getSalutation = (
  salutationOptions: SelectOptionType[],
  checkValue: string,
): SelectOptionType => {
  return salutationOptions.filter((option: SelectOptionType) => option.value === checkValue)[0];
};

/**
 * Provides translations for the salutation dropdown
 */
export const useSalutations = () => {
  const { t } = useTranslation();
  return [
    { label: t('data.personalInfo.salutationOptions.woman'), value: CustomerSalutation.Ms },
    { label: t('data.personalInfo.salutationOptions.man'), value: CustomerSalutation.Mr },
  ];
};

/**
 * Removes the leading zero if string starts with it
 * @param string
 */
export const removeLeadingZero = (input: string): string =>
  input.startsWith('0') ? input.slice(1) : input;

/**
 * Allows only one empty space by removing all the next ones
 * @param string
 */
export const removeEmptySpaces = (value: string) => value.replace(/\s+/g, ' ');

/*
 * Retrieves grid purchase value or returns 0 if site does not have grid purchase
 * @param site
 */
export const getGridPurchase = (site?: CurrentUserSite | null) =>
  site?.previousYearGridPurchase?.aggregation
    ? roundWithPrecision(site?.previousYearGridPurchase?.aggregation)
    : 0;

/**
 * Retrieves battery id from site object
 * @param site
 */
export const getBatteryId = (site: CurrentUserSite | null) => site?.batteries?.[0]?.id;

/**
 * Gets the default site to be displayed as value in the sites dropdown, following this prioritization:
 * 1. site with battery, no contract and grid purchase
 * 2. site with battery, no contract, no grid purchase
 * 3. site with no battery and no contract
 * 4. if none apply, it shows the first site in the array, which will trigger the modal
 * Returns null if there is no user or no sites
 * @param user
 */
export const getDefaultSite = (user: CurrentUser | null): CurrentUserSite | null => {
  if (!user?.sites || !user.sites.length) return null;

  const sitesWithBatteries = user.sites.filter((site) => site.options.hasBatteries);
  const siteWithGridPurchase = sitesWithBatteries.find(
    (site) => site.options.isAllowedToCreateContract && site.previousYearGridPurchase,
  );
  const siteWithoutGridPurchase = sitesWithBatteries.find(
    (site) => site.options.isAllowedToCreateContract,
  );
  const sitesAllowedToCreateContract = user.sites.find(
    (site) => site.options.isAllowedToCreateContract,
  );

  return (
    siteWithGridPurchase || siteWithoutGridPurchase || sitesAllowedToCreateContract || user.sites[0]
  );
};

/**
 * Returns address without values of type null, undefined or Maybe<>
 */
export const completeAddress = (
  address: TAddress | CurrentUserAccountBillingAddress,
): TCompleteAddress => ({
  city: address.city || '',
  country: address.country || '',
  postalCode: address.postalCode || '',
  streetName: address.streetName || '',
  streetNumber: address.streetNumber || '',
});

export const getActualBillingAddress = (
  formValues: Pick<TFormValues, 'billingAddress' | 'deliveryAddress'>,
  useCustomBillingAddress: boolean,
): TCompleteAddress => {
  return completeAddress(
    formValues[useCustomBillingAddress ? 'billingAddress' : 'deliveryAddress'],
  );
};

export const camelToSnake = (key: string) => {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('-').toLowerCase();
};

export const businessAccount = (account: CustomerUserAccountType) =>
  account === CustomerUserAccountType.BusinessCustomer;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export const ExternalLinkComponent = ({ node, ...props }: any) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-props-no-spreading
  <a {...props} target="_blank" rel="noopener noreferrer" />
);

export const moduleAvailable = (offer: ProductConfigurations | null, name: ModuleName) => {
  return Boolean(offer?.[modulePropertyByName[name]]);
};

export const memoize = (children: JSX.Element) => useMemo(() => children, []);
