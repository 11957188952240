import { Typography } from '@sonnen/web-ui';
import CheckmarkLines from '../../../components/CheckmarkLines/CheckmarkLines';
import { useTranslation } from '../../../i18n/i18n';

import styles from './CalculationCard.module.css';
import OfferCalculationForm from '../../OfferCalculation/OfferCalculationForm/OfferCalculationForm';

const CalculationCard = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.calculationCard} data-testid="calculation-card">
      <div className={styles.content}>
        <span className={styles.text}>
          <Typography.H2>{t('login.visitorTitle')} </Typography.H2>
          <CheckmarkLines list={t('login.visitorBulletPoints').split('\n')} />
        </span>

        <OfferCalculationForm />
      </div>
    </div>
  );
};

export default CalculationCard;
