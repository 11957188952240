import { Layout } from '@sonnen/web-ui';
import { useAuth } from '@sonnen/web-utils/hooks';
import { useNavigate } from 'react-router-dom';
import CalculationCard from './CalculationCard/CalculationCard';
import LoginCard from './LoginCard/LoginCard';

import styles from './Home.module.css';
import { ROUTE_FLOW } from '../../constants';

const Home = () => {
  const { user: authenticatedUser } = useAuth();
  const navigate = useNavigate();

  if (authenticatedUser) {
    navigate(ROUTE_FLOW[1]);
    return null;
  }

  return (
    <div className={styles.homePage} data-testid="home-page">
      <Layout centerVertically>
        <div className={styles.cardsWrapper}>
          <LoginCard />
          <CalculationCard />
        </div>
      </Layout>
    </div>
  );
};

export default Home;
