import { ButtonIcon, Layout, Typography } from '@sonnen/web-ui';
import { useAuth } from '@sonnen/web-utils/hooks';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { nextLabelByPath, ROUTE_FLOW } from '../../constants';
import { useOrderFlowNavigation } from '../../hooks/useOrderFlowNavigation';
import { usePathname } from '../../hooks/usePathname';
import { useTranslation } from '../../i18n/i18n';
import OfferCard from '../OfferCard/OfferCard';
import styles from './OrderFlowPage.module.css';
import { memoize } from '../../utils/utils';

export type OrderFlowPageProps = {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  title?: string;
};

export const OrderFlowPage = ({ children, className, dataTestId, title }: OrderFlowPageProps) => {
  const { user: authenticatedUser } = useAuth();
  const pathname = usePathname();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { navigateNext, navigatePrevious, skippableRoute } = useOrderFlowNavigation();
  const nextLabel = t(nextLabelByPath[pathname] || 'offer.offerBtn');
  const offerCalculationPage = pathname === ROUTE_FLOW[1];
  const showBackButton = !authenticatedUser || !offerCalculationPage;

  const onClick = () => {
    if (offerCalculationPage) navigate(ROUTE_FLOW[0]);
    else if (navigatePrevious) navigatePrevious();
  };

  return (
    <div className={classNames(styles.orderFlowPage, className)} data-testid={dataTestId}>
      <Layout centerVertically>
        {memoize(
          <div className={styles.titleBar}>
            {showBackButton && (
              <div className={styles.navBtn}>
                <ButtonIcon
                  dataTestId="go-back-btn"
                  icon="ChevronLeft"
                  ariaLabel="go-back"
                  size="xl"
                  onClick={onClick}
                />
              </div>
            )}

            {title && (
              <div className={styles.title}>
                <Typography.H1>{title}</Typography.H1>
              </div>
            )}
            <div />
          </div>,
        )}
        <div className={styles.formAndOfferLayout}>
          <div className={styles.formCard}>{children}</div>
          {skippableRoute && navigateNext ? (
            <OfferCard onOfferBtnClick={navigateNext} buttonLabel={t(nextLabel)} />
          ) : (
            <OfferCard />
          )}
        </div>
      </Layout>
    </div>
  );
};
