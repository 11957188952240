import { Typography } from '@sonnen/web-ui';
import styles from './PriceCards.module.css';
import { TPriceCard } from '../../types';
import { parseHtmlTags } from '@sonnen/web-utils';

type PriceCardsProps = {
  cards: TPriceCard[];
};

export const PriceCards = ({ cards }: PriceCardsProps) => (
  <div className={styles.priceCards}>
    {cards.map((card) => (
      <div className={styles.priceCard} key={card.title}>
        <Typography.H3>{parseHtmlTags(card.title)}</Typography.H3>
        <Typography.Caption1>{card.caption}</Typography.Caption1>
      </div>
    ))}
  </div>
);
