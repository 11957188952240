import { Topbar, Typography, Button } from '@sonnen/web-ui';
import { memo, useContext } from 'react';
import { useAuth } from '@sonnen/web-utils/hooks';
import { getFullName, getLogoutRedirectUri } from '../../utils/utils';
import { AppContext } from '../../AppContext/AppContext';
import { CurrentUser } from '../../graphql/generated';
import { useTranslation } from '../../i18n/i18n';

import styles from './TopbarWithNameAndLogout.module.css';

const TopbarWithNameAndLogoutBtn = () => {
  const { authenticated } = useAuth();
  const { user, userProcessing } = useContext(AppContext);
  const { logout } = useAuth<CurrentUser>();
  const { t } = useTranslation();
  const redirectUri = getLogoutRedirectUri();

  return (
    <Topbar>
      <div className={styles.topbarWithNameAndLogout}>
        {user && !userProcessing && (
          <Typography.Body1 truncate>{getFullName(user.contact)}</Typography.Body1>
        )}

        {authenticated && (
          <Button
            label={t('logoutButtonLabel')}
            dataTestId="logout-btn"
            variant="secondary"
            onClick={() => logout(redirectUri)}
            size="small"
          />
        )}
      </div>
    </Topbar>
  );
};

export default memo(TopbarWithNameAndLogoutBtn);
