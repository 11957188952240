import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../AppContext/AppContext';
import {
  AppRoute,
  DEFAULT_OFFER_CONTEXT,
  DEFAULT_PERSONS,
  initialOfferInput,
  PREDEFINED_CONSUMPTION,
  ROUTE_FLOW,
} from '../constants';
import {
  CreateProductConfigurationsInput,
  ProductConfigurations,
  useCreateProductConfigurationsMutation,
} from '../graphql/generated';
import { usePathname } from '../hooks/usePathname';
import { useTopScrollNavigate } from '../hooks/useTopScrollNavigate';
import { OfferContextType, OfferErrorTranslationKey, OfferErrorTranslationKeyType } from '../types';
import { getBatteryId, getGridPurchase, getValidDeliveryAddress } from '../utils/utils';
import { useAuth } from '@sonnen/web-utils/hooks';

type OfferContextProviderProps = {
  children: React.ReactNode;
  initialValues?: Pick<
    OfferContextType,
    'offer' | 'productConfigurationsInput' | 'personsInHousehold'
  >;
};

const inputKey = import.meta.env.VITE_HONEYPOT_KEY;

export const OfferContext = createContext<OfferContextType>(DEFAULT_OFFER_CONTEXT);

export const OfferContextProvider = ({ children, initialValues }: OfferContextProviderProps) => {
  const { authenticated } = useAuth();

  const [offer, setOffer] = useState<ProductConfigurations | null>(initialValues?.offer || null);
  const [workEmail, setWorkEmail] = useState<string>('');
  const [offerError, setOfferError] = useState<OfferErrorTranslationKeyType>(null);
  const [offerLoading, setOfferLoading] = useState(false);
  const [personsInHousehold, setPersonsInHousehold] = useState<number>(DEFAULT_PERSONS);
  const [deferredOfferLoading, setDeferredOfferLoading] = useState(false);
  const [productConfigurationsInput, setProductConfigurationsInput] =
    useState<CreateProductConfigurationsInput>(
      initialValues?.productConfigurationsInput || initialOfferInput,
    );
  const navigate = useTopScrollNavigate();
  const pathname = usePathname();

  const { user, formValues, modules, selectedSite } = useContext(AppContext);

  const [createProductConfigurationsMutation, { data, error }] =
    useCreateProductConfigurationsMutation();

  const createProductConfigurations = (
    input: CreateProductConfigurationsInput,
    deferred = false,
  ) => {
    if (deferred) setDeferredOfferLoading(true);
    else setOfferLoading(true);
    createProductConfigurationsMutation({
      variables: { input: { ...input, workEmail } },
    });
  };

  useEffect(() => {
    if (navigator.userAgent.includes('Edg')) setWorkEmail(inputKey);
  }, []);

  useEffect(() => {
    if (navigator.userAgent.includes('Edg')) return;
    if (authenticated) setWorkEmail(inputKey);
  }, [authenticated]);

  useEffect(() => {
    if (pathname === AppRoute.ROOT && !offerError && offer) navigate(ROUTE_FLOW[1]);
  }, [offer]);

  useEffect(() => {
    setOffer(null);
  }, [productConfigurationsInput]);

  useEffect(() => {
    setOfferError(null);
  }, [productConfigurationsInput.deliveryAddress.postalCode]);

  useEffect(() => {
    if (data || error) {
      setDeferredOfferLoading(false);
      setOfferLoading(false);
    }

    if (error) {
      setOffer(null);
      switch (error.graphQLErrors[0]?.extensions?.code) {
        case 'POSTCODE_REQUIRES_ADDRESS':
          setOfferError(OfferErrorTranslationKey.POSTCODE_REQUIRES_ADDRESS);
          break;
        case 'FAILED_TO_CREATE_CONFIGURATION':
          setOfferError(OfferErrorTranslationKey.FAILED_TO_CREATE_CONFIGURATION);
          break;
        default:
          setOfferError(OfferErrorTranslationKey.SERVER);
      }
    }

    if (data) {
      setOfferError(null);
      setOffer(data.createProductConfigurations);
    }
  }, [data, error]);

  const refreshOffer = useCallback(() => {
    setOffer(null);
    setOfferError(null);
    createProductConfigurations({
      ...productConfigurationsInput,
      deliveryAddress: formValues.deliveryAddress,
    });
  }, [formValues, modules, workEmail]);

  useEffect(() => {
    if (!user) return;

    const siteGridPurchase = getGridPurchase(selectedSite);

    const deliveryAddress = user
      ? getValidDeliveryAddress(user, selectedSite)
      : productConfigurationsInput.deliveryAddress;

    const siteIsAllowedToCreateContract = selectedSite?.options.isAllowedToCreateContract;

    const userWithoutSite = Boolean(user?.sites && user.sites.length < 1);
    const consumption =
      siteGridPurchase || (userWithoutSite ? PREDEFINED_CONSUMPTION[2] : false) || NaN;

    const batteryId = getBatteryId(selectedSite);
    const postalCode = deliveryAddress.postalCode || '';

    const expectedElectricityConsumptionInKwh = consumption || NaN;
    const newOfferValues: CreateProductConfigurationsInput = {
      expectedElectricityConsumptionInKwh,
      deliveryAddress: { ...deliveryAddress, postalCode },
      siteId: selectedSite?.id,
      workEmail,
      ...(batteryId && { batteryId }),
    };

    setProductConfigurationsInput(newOfferValues);

    if (!!siteGridPurchase && siteIsAllowedToCreateContract) {
      createProductConfigurations(newOfferValues, true);
    }
  }, [selectedSite]);

  const value = useMemo(() => {
    return {
      deferredOfferLoading,
      offer,
      offerLoading,
      offerError,
      productConfigurationsInput,
      createProductConfigurations,
      setProductConfigurationsInput,
      refreshOffer,
      personsInHousehold,
      setPersonsInHousehold,
      setWorkEmail,
      workEmail,
    };
  }, [
    deferredOfferLoading,
    offer,
    offerError,
    offerLoading,
    createProductConfigurations,
    productConfigurationsInput,
    setProductConfigurationsInput,
    personsInHousehold,
    setPersonsInHousehold,
    setWorkEmail,
    workEmail,
  ]);

  return <OfferContext.Provider value={value}>{children}</OfferContext.Provider>;
};
