import { Layout, Typography } from '@sonnen/web-ui';
import { useContext } from 'react';
import { useTranslation } from '../../i18n/i18n';
import { parseHtmlTags } from '@sonnen/web-utils';
import { CheckAnimation } from './CheckAnimation/CheckAnimation';
import styles from './Confirmation.module.css';

import { AppContext } from '../../AppContext/AppContext';
import { AppContextType } from '../../types';

export const Confirmation = () => {
  const { t } = useTranslation();
  const { formValues } = useContext<AppContextType>(AppContext);

  const message = parseHtmlTags(
    t('confirmation.thankYouMessage', {
      email: formValues.personalData.email,
    }),
  );

  return (
    <Layout centerContent centerVertically fullViewportHeight>
      <div className={styles.confirmationText} data-testid="order-complete">
        <CheckAnimation />
        <Typography.H2>{t('confirmation.headline')}</Typography.H2>
        <Typography.Body1>{message}</Typography.Body1>
      </div>
    </Layout>
  );
};
