import {
  ButtonIcon,
  ChevronDown,
  ChevronUp,
  Gallery,
  SectionTitle,
  Typography,
} from '@sonnen/web-ui';
import classNames from 'classnames';
import { useState } from 'react';
import meter1 from '../../../assets/images/meters/meter1.webp';
import meter2 from '../../../assets/images/meters/meter2.webp';
import meter3 from '../../../assets/images/meters/meter3.webp';
import meter4 from '../../../assets/images/meters/meter4.webp';
import { useTranslation } from '../../../i18n/i18n';
import styles from './MeterInfo.module.css';
import { parseHtmlTags } from '@sonnen/web-utils';

export const MeterInfo = () => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div data-testid="meter-info" className={styles.meterInfo}>
      <div className={styles.expander} data-testid="meter-info-expander">
        <div className={styles.logoText}>
          <ButtonIcon
            ariaLabel={expanded ? 'show gallery' : 'hide gallery'}
            dataTestId="info-button"
            icon="Info"
            onClick={toggleExpanded}
          />
          <button onClick={toggleExpanded} className={styles.meterInfoText}>
            <Typography.Body2>{t('data.dataEntry.meterInfo.info')}</Typography.Body2>
          </button>
        </div>
        <button onClick={toggleExpanded}>
          {expanded ? <ChevronUp color="interactive" /> : <ChevronDown color="interactive" />}
        </button>
      </div>

      <div
        className={classNames(styles.meterGallery, { [styles.expanded]: expanded })}
        data-testid="meter-gallery"
      >
        <SectionTitle className={styles.sectionTitle} size="lg">
          {t('data.dataEntry.meterInfo.title')}
        </SectionTitle>
        <Typography.Body1>{parseHtmlTags(t('data.dataEntry.meterInfo.text'))}</Typography.Body1>

        <Gallery
          dataTestId="meter-gallery-component"
          backgroundSize="contain"
          items={[{ src: meter1 }, { src: meter2 }, { src: meter3 }, { src: meter4 }]}
        />
      </div>
    </div>
  );
};
