import '@sonnen/web-ui/dist/web-ui.css';
import { ApolloProviderWrapper, ApolloProviderWrapperConfig } from '@sonnen/web-utils/components';
import { useAuth } from '@sonnen/web-utils/hooks';
import { useEffect, useMemo } from 'react';
import { AppRoutes } from './AppRoutes/AppRoutes';
import { OfferContextProvider } from './OfferContext/OfferContext';

import './App.css';

const removeUser = () => localStorage.removeItem('web-utils:user');

const App = () => {
  const { user: authenticatedUser } = useAuth();

  const config: ApolloProviderWrapperConfig = useMemo(
    () => ({ apiUri: process.env.VITE_GRAPHQL_API }),
    [authenticatedUser],
  );

  useEffect(() => {
    window.addEventListener('webview-unload', removeUser);
    return () => window.removeEventListener('webview-unload', removeUser);
  }, [authenticatedUser]);

  return (
    <ApolloProviderWrapper config={config}>
      <OfferContextProvider>
        <AppRoutes />
      </OfferContextProvider>
    </ApolloProviderWrapper>
  );
};

export default App;
