import { useMobile, Typography } from '@sonnen/web-ui';
import { GraphDesktop } from '../../../assets/GraphDesktop';
import { GraphMobile } from '../../../assets/GraphMobile';
import styles from './EnergyDynamicUSP.module.css';
import { useTranslation } from '../../../i18n/i18n';

export const EnergyDynamicUSP = () => {
  const mobile = useMobile();
  const { t } = useTranslation();

  return (
    <div data-testid="energy-dynamic-usp">
      {mobile ? <GraphMobile /> : <GraphDesktop />}
      <div className={styles.legend}>
        <Typography.Caption1 variant="secondary">{t('home.cardChart.legend')}</Typography.Caption1>
      </div>
    </div>
  );
};
