import { ButtonIcon } from '@sonnen/web-ui';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext/AppContext';
import { OfferContext } from '../../../OfferContext/OfferContext';
import { ModuleName } from '../../../types';

type AddRemoveModuleProps = {
  name: ModuleName;
};

const AddRemoveModule = ({ name }: AddRemoveModuleProps) => {
  const { toggleModule, modules } = useContext(AppContext);
  const { offerLoading } = useContext(OfferContext);
  const selected = modules.has(name);

  const onClick = () => toggleModule(name);

  return (
    <ButtonIcon
      dataTestId="add-remove-module"
      icon={selected ? 'Delete' : 'Plus'}
      ariaLabel="add-remove-module"
      onClick={onClick}
      size="sm"
      disabled={offerLoading}
    />
  );
};

export default AddRemoveModule;
