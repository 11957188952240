import { IconName, Selection } from '@sonnen/web-ui';
import { SelectOptionType } from '../../types';
import styles from './Selections.module.css';

export type IconOption = SelectOptionType & {
  icon: IconName;
};

type SelectionsProps = {
  options: IconOption[];
  selected: string;
  onChange: (selected: string) => void;
};

export const Selections = ({ options, selected, onChange }: SelectionsProps) => (
  <div data-testid="selections" className={styles.selections}>
    {options.map((option) => (
      <Selection
        dataTestId={`selection-${option.value}`}
        key={option.value}
        onClick={() => onChange(option.value)}
        leadingIcon={option.icon}
        data-testid={`selection-${option.value}`}
        state={selected === option.value ? 'selected' : 'default'}
        label={option.label}
      />
    ))}
  </div>
);
