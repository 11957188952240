import { AccordionList, Button, Card, Line, Typography } from '@sonnen/web-ui';
import { memo, useContext, useMemo } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { OfferContext } from '../../OfferContext/OfferContext';
import { useTranslation } from '../../i18n/i18n';
import { ModuleName } from '../../types';
import { formatCurrency } from '../../utils/formatting';
import OfferOverlay from '../OfferOverlay/OfferOverlay';
import StatusTag from '../StatusTag/StatusTag';
import SummaryCard from '../SummaryCard/SummaryCard';
import ModuleRow from './ModuleRow/ModuleRow';
import styles from './OfferCard.module.css';
import { PriceOptimizationModuleRow } from './PriceOptimizationModuleRow/PriceOptimizationModuleRow';
import { AppRoute, ROUTE_FLOW } from '../../constants';
import AddRemoveModule from './AddRemoveModule/AddRemoveModule';
import { usePathname } from '../../hooks/usePathname';

type OfferCardType =
  | {
      buttonLabel: string;
      onOfferBtnClick: () => void;
    }
  | {
      buttonLabel?: never;
      onOfferBtnClick?: never;
    };

const OfferCard = ({ buttonLabel, onOfferBtnClick }: OfferCardType) => {
  const { t } = useTranslation();
  const { user, modules } = useContext(AppContext);
  const pathname = usePathname();
  const { offer, offerLoading, offerError, deferredOfferLoading, productConfigurationsInput } =
    useContext(OfferContext);

  const configuration =
    offer?.configurationWithBatteryOptimizationModule ||
    offer?.configurationWithoutBatteryOptimizationModule;

  const {
    baseFeeGrossPerMonth,
    purchaseTariffGrossPerKwh,
    expectedPaymentGrossPerMonth,
    fixedCostsGrossPerMonth,
    terminationPeriodOption,
  } = configuration || {};

  const showPriceOptimizationModule =
    ROUTE_FLOW.indexOf(pathname) > 1 &&
    user &&
    offer?.configurationWithBatteryOptimizationModule &&
    modules.has(ModuleName.PRICE_OPTIMIZATION);

  const listData = useMemo(() => {
    const listEntries: Record<string, string | null> = {
      priceDynamic: t(`offer.accordion.priceDynamic.value`),
      purchaseTariffGrossPerKwh: `${formatCurrency(purchaseTariffGrossPerKwh, 'ct')}/kWh`,
      energy2Optimize: t(`offer.accordion.energy2Optimize.value`),
      baseFeeGrossPerMonth: `${formatCurrency(baseFeeGrossPerMonth, 'euro')}/${t('offer.month')}`,
      gridFees: `${formatCurrency(fixedCostsGrossPerMonth, 'euro')}/${t('offer.month')}`,
      minimumContractDuration: t(`offer.accordion.minimumContractDuration.value`),
      terminationPeriodOption: t(`offer.accordion.terminationPeriodOption.value`),
    };

    if (!showPriceOptimizationModule) delete listEntries.energy2Optimize;

    return Object.entries(listEntries).map(([key, value]) => ({
      label: t(`offer.accordion.${key}.label`),
      value: value ?? t(`offer.accordion.${key}.value`),
      children: t(`offer.accordion.${key}.description`, { default: '' }),
      dataTestId: key,
    }));
  }, [
    baseFeeGrossPerMonth,
    purchaseTariffGrossPerKwh,
    fixedCostsGrossPerMonth,
    showPriceOptimizationModule,
    terminationPeriodOption,
    t,
  ]);

  if (!offer && !offerLoading && !offerError) return null;

  return (
    <div data-testid="offer-card" id="offer-card">
      <Card paddingSize="md" className={styles.offerCard}>
        <OfferOverlay insideCard />

        <div className={styles.titleWithLogo}>
          <Typography.H1>{t('offer.title')}</Typography.H1>
          <span className={styles.tag}>
            <StatusTag text={t('offer.greenLabel')} />
          </span>
        </div>

        <div className={styles.summaryTariffCardWrapper}>
          <div className={styles.modules}>
            <ModuleRow
              name="EnergyDynamic"
              value={formatCurrency(expectedPaymentGrossPerMonth, 'euro', false)}
              currency={`€/${t('offer.month')}`}
              description={t('modules.energyDynamic')}
              dataTestId="energy-dynamic"
            />

            {showPriceOptimizationModule && (
              <>
                <div className={styles.addRemoveModule}>
                  <Line />
                  {pathname === AppRoute.PRICE_OPTIMIZATION && (
                    <AddRemoveModule name={ModuleName.PRICE_OPTIMIZATION} />
                  )}
                </div>
                <PriceOptimizationModuleRow />
              </>
            )}
          </div>

          <SummaryCard
            title={t('offer.summaryCardTitle')}
            value={formatCurrency(expectedPaymentGrossPerMonth, 'euro', false)}
            currency={`€/${t('offer.month')}`}
            subtitle={t('offer.summaryCardSubtitle', {
              value: productConfigurationsInput.expectedElectricityConsumptionInKwh,
            })}
          />
        </div>

        <AccordionList list={listData} dataTestId="offer" />

        {onOfferBtnClick && buttonLabel && (
          <Button
            label={buttonLabel}
            onClick={onOfferBtnClick}
            dataTestId="offer-card-btn"
            loading={deferredOfferLoading}
            disabled={Boolean(offerError)}
            expanded
          />
        )}
      </Card>
    </div>
  );
};

export default memo(OfferCard);
